// ===============================
// Object
// ===============================

// component
.privacy_c-section {
  @include styleS((
    margin-top: 90,
    padding-left: 36,
    padding-right: 36,
    font-size: 12,
  ));

  @include sizeL {
    margin-left: auto;
    margin-right: auto;
    margin-top: 90px;
    max-width: 936px;
    padding-left: 160px;
    padding-right: 160px;
    font-size: 12px;
  }

  @include sizeM {
    padding-left: 100px;
    padding-right: 100px;
  }
}

.privacy_c-sectionTitle {
  @include styleS((
    font-size: 15,
    margin-bottom: 40
  ));

  @include sizeL {
    font-size: 16px;
    margin-bottom: 40px;
  }
}

.privacy_c-txt {
  line-height: 2.5;

  @include styleS((
    font-size: 12,
  ));

  @include sizeL {
    font-size: 12px;
  }
}

.privacy_c-numberedTitle {
  line-height: 2.5;

  @include styleS((
    font-size: 12,
  ));

  @include sizeL {
    font-size: 12px;
  }
}

.privacy_c-list {
  list-style-position: outside;
  list-style-type: none;

  @include styleS((
    font-size: 12,
  ));

  @include sizeL {
    font-size: 12px;
  }

  li {
    position: relative;
    padding-left: 1em;

    &::before {
      @include absoluteTopLeft;
      content: '・';
    }
  }
}

.privacy_c-orderedList {
  list-style-position: outside;
  list-style-type: decimal;
  padding-left: 1em;

  @include styleS((
    font-size: 12,
  ));

  @include sizeL {
    font-size: 12px;
  }
}
.privacy_c-orderedList--type2 {
  list-style: none;
  padding-left: 0;

  & > li {
    counter-increment: listCount;
    position: relative;
    padding-left: 2em;

    &::before {
      @include absoluteTopLeft;
      content: "("counter(listCount)")";
    }
  }
}
.privacy_c-orderedList--type3 {
  list-style: none;
  padding-left: 0;

  & > li {
    position: relative;
    padding-left: 2em;

    &::before {
      @include absoluteTopLeft;
    }

    &:nth-child(1)::before {
      content: 'ア)'
    }
    &:nth-child(2)::before {
      content: 'イ)'
    }
    &:nth-child(3)::before {
      content: 'ウ)'
    }
  }
}


// Project

.privacy_p-main {
  position: relative;
  display: block;

  .c-smallTitle {
    justify-content: center;
  }
}

.privacy_p-date,
.privacy_p-company,
.privacy_p-contact {
  @extend .privacy_u-marginTop;
  @extend .privacy_u-marginBottom;
}

.privacy_c-end {
  @extend .privacy_u-marginTop;
}


// utility
.privacy_u-marginTop {
  margin-top: 2em;
}

.privacy_u-marginBottom {
  margin-bottom: 2em;
}

.privacy_u-marginLeft {
  margin-left: 1em;
}
.privacy_u-negativeMarginLeft {
  margin-left: -1em;
}
// ===============================
// Object
// ===============================

// component



// Project

.recruit_p-main {
  position: relative;
  display: block;
}

.recruit_p-message {
  position: relative;

  @include styleS((
    padding-top: 100,
    padding-bottom: 164
  ));

  @include sizeL {
    padding-top: 140px;
    padding-bottom: 254px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }

  .c-copyL {
    margin-left: auto;
    margin-right: auto;
    line-height: 2.8;
    white-space: nowrap;
    letter-spacing: 0 !important;

    @include styleS((
      font-size: 18
    ));

    @include sizeL {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      line-height: 2;
      font-size: 26px;
      letter-spacing: 0.2em !important;
      width: 936px;
    }
    @include sizeM {
      font-size: 22px;
      width: 760px;
    }
  }

  .c-smallTitle {
    @include styleS((
      margin-bottom: 90,
    ));
    @include sizeL {
      margin-bottom: 64px;
    }
  }
}


// interview
.recruit_p-interview {
  @include styleS((
    padding-bottom: 156
  ));

  @include sizeL {
    padding-bottom: 256px;
  }

  .c-smallTitle {
    @include styleS((
      margin-bottom: 90,
    ));
    @include sizeL {
      margin-bottom: 100px;
    }
  }
}

.recruit_p-interviewItem {
  position: relative;

  @include styleS((
    margin-top: 80,
  ));

  @include sizeL {
    margin-top: 98px;
  }

  &:first-child {
    margin-top: 0;
  }
}

.recruit_p-interviewItem__inner {
  display: block;
}

.recruit_p-interviewItem__num {
  position: absolute;
  letter-spacing: 0.06em !important;
  z-index: 2;
  transition: color 0.2s linear;


  @include styleS((
    font-size: 68,
    top: -30
  ));

  @include sizeL {
    font-size: 72;
    top: -40px;
  }

  .recruit_p-interviewItem:nth-child(odd) & {
    left: 0;
  }
  .recruit_p-interviewItem:nth-child(even) & {
    right: 0;
  }
}

.recruit_p-interviewItem__head {
  position: relative;
  width: 100%;

  @include styleS((
    margin-top: -46,
  ));

  @include sizeL {
    @include absoluteCenterVT;
    margin-top: auto;
    width: 320px;

    .recruit_p-interviewItem:nth-child(odd) & {
      right: 0;
    }
    .recruit_p-interviewItem:nth-child(even) & {
      left: 0;
    }
  }
}

.recruit_p-interviewItem__img {
  position: relative;
  overflow: hidden;
  width: 314 / 340 * 100%;
  transition: opacity 0.2s linear;
  opacity: 0.5;

  @include sizeL {
    width: 623 / 936 * 100%;

    .recruit_p-interviewItem:nth-child(odd) & {
      margin-left: 40px;
    }
    .recruit_p-interviewItem:nth-child(even) & {
      margin-left: 262px;
    }
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 330 / 623 * 100%;
  }

  img {
    @include inner100Absolute;
    object-fit: cover;
    transition: transform 2s $easeOutExpo,
                opacity 0.4s linear !important;
    object-fit: cover;
  }


  .recruit_p-interviewItem:nth-child(odd) & {
    @include styleS((
      margin-left: 24,
    ));
  }
}

.recruit_p-interviewItem__head {
  position: relative;
}

.recruit_p-interviewItem__names {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1em;
}

.recruit_p-interviewItem__nameTitle {
  display: block;
  margin-right: 1em;
  transition: color 0.2s linear;

  @include styleS((
    font-size: 12,
  ));

  @include sizeL {
    font-size: 12px;
  }
}

.recruit_p-interviewItem__name {
  display: block;
  font-weight: bold;
  transition: color 0.2s linear;

  @include styleS((
    font-size: 15,
  ));

  @include sizeL {
    font-size: 15px;
  }
}

.recruit_p-interviewItem__title {
  line-height: 1.73;
  font-weight: bold;
  transition: color 0.2s linear;

  @include styleS((
    font-size: 15,
  ));

  @include sizeL {
    font-size: 20px;
    line-height: 2.15;
  }
}

.recruit_p-interviewItem {
  &.is-onScreenItem {
    opacity: 0;
    &:nth-child(odd) {
      transform: translateX(-6%);
    }
    &:nth-child(even) {
      transform: translateX(6%);
    }

    &.is-onScreen {
      transition: opacity 0.4s linear,
                  transform 1.6s $easeOutExpo;
      opacity: 1;
      transform: translateX(0);
    }
  }
}

@include hover {
  .recruit_p-interviewItem:hover {
    .recruit_p-interviewItem__img {
      transition-duration: 0.1s;
      opacity: 1;

      img {
        transform: scale(1.1);
      }
    }
    .recruit_p-interviewItem__title,
    .recruit_p-interviewItem__nameTitle,
    .recruit_p-interviewItem__name,
    .recruit_p-interviewItem__num {
      color: $colorOrange;
      transition-duration: 0.1s;
    }
  }
}




// requirements
.recruit_p-requirements {
  .c-smallTitle {
    @include styleS((
      margin-bottom: 64,
    ));
    @include sizeL {
      margin-bottom: 64px;
    }
  }
}

.recruit_p-requirements__list {

}

.recruit_p-requirementsItem {
  @include styleS((
      margin-top: 64,
    ));
    @include sizeL {
      margin-top: 64px;
    }
  &:first-child {
    margin-top: 0;
  }
}

.recruit_p-requirementsItem__title {
  line-height: 1.85;
  font-weight: bold;

  @include styleS((
    font-size: 20,
    margin-bottom: 60
  ));
  @include sizeL {
    font-size: 27px;
    margin-bottom: 60px;
  }
}

.recruit_p-requirementsItemInfo {

}

.recruit_p-requirementsItemInfo__Group {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  @include styleS((
    margin-top: 32,
  ));
  @include sizeL {
    margin-top: 40px;
  }

  &:first-child {
    margin-top: 0;
  }
}

.recruit_p-requirementsItemInfo__label {
  line-height: 2.5;
  white-space: nowrap;

  @include styleS((
    font-size: 12,
    min-width: 84,
  ));

  @include sizeL {
    font-size: 12px;
    min-width: 110px;
  }
}

.recruit_p-requirementsItemInfo__contents {
  line-height: 2.5;

  @include styleS((
    font-size: 12,
  ));

  @include sizeL {
    line-height: 2;
    font-size: 15px;
  }
}


.recruit_p-entryLink {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 9em;

  @include styleS((
    margin-top: 60,
  ));

  @include sizeL {
    margin-top: 120px;
  }

  .c-circleArrowLink__inner {
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
  }

  .c-circleArrowLink__label {
    font-weight: bold;
    margin-right: 0.4em;

    @include styleS((
      font-size: 32,
    ));

    @include sizeL {
      font-size: 32px;
    }

    &::after {
      display: none;
    }
  }

  .c-circleArrowLink__circleArrow {
    margin-top: 0.1em;

    @include styleS((
      min-width: 30,
      height: 30,
    ));

    @include sizeL {
      min-width: 30px;
      width: 30px;
      height: 30px;
    };
  }
}



.recruit_p-entryLink__labelJa {
  display: block;
  margin-top: 0.6em;
  font-weight: normal;

  @include styleS((
    font-size: 15,
  ));
  @include sizeL {
    font-size: 15px;
  }
}



// modal
.recruit_p-modalHead {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.recruit_p-modalHead__num {
  margin-top: -0.2em;
  margin-right: -0.1em;
  white-space: nowrap;

  @include styleS((
    font-size: 45,
    margin-bottom: 30
  ));
  @include sizeL {
    font-size: 64px;
    margin-bottom: 30px;
  }
}
.recruit_p-modalHead__title {
  font-weight: bold;
  margin-bottom: 1em;
  white-space: nowrap;

  @include styleS((
    font-size: 12,
  ));
  @include sizeL {
    font-size: 12px;
  }
}
.recruit_p-modalHead__name {
  white-space: nowrap;
  font-weight: bold;

  @include styleS((
    font-size: 14,
  ));
  @include sizeL {
    font-size: 15px;
  }
}

.recruit_p-modalContents {
  position: relative;
  width: 100%;
}

.recruit_p-modalContents__img {
  position: relative;

  img {
    width: 100%;
    height: auto;
  };
}


.recruit_p-modalContents__title {
  font-weight: bold;
  line-height: 2.15;
  border-bottom: 1px solid #fff;

  @include styleS((
    font-size: 20,
    margin-top: 40,
    padding-bottom: 20
  ));

  @include sizeL {
    font-size: 20px;
    margin-top: 32px;
    padding-bottom: 24px;
  }
}

.recruit_p-modalContents__body {
  line-height: 2.5;

  @include styleS((
    font-size: 12,
    padding-top: 20
  ));

  @include sizeL {
    font-size: 12px;
    padding-top: 26px;
  }

  p {
    margin-top: 2em;

    &:first-child {
      margin-top: 0;
    }
  }
}


.recruit_p-interviewContentsList {
  display: none;
}
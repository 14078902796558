.p-footer {
  position: relative;
  width: 100%;

  @include styleS((
    padding-top: 146,
    padding-bottom: 46,
  ));

  @include sizeL {
    padding-top: 120px;
    padding-bottom: 86px;
  }
}

.p-footer__inner {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @include styleS((
    padding-left: 36,
    padding-right: 36
  ));

  @include sizeL {
    max-width: 1024px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 90px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.p-footer__logo {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.p-footer__logoMark {
  fill: $colorWhite;
  transition: fill 0.2s linear;
  display: grid;
  transform: translateY(4%);

  @include styleS((
    width: 50,
    height: 50,
    margin-right: 14
  ));

  @include sizeL {
    width: 38px;
    height: 36px;
    margin-right: 16px;
  }
}

.p-footer__logoTxt {
  fill: $colorWhite;
  transition: fill 0.2s linear;
  display: block;

  @include styleS((
    width: 110,
    height: 17,
  ));

  @include sizeL {
    width: 85px;
    height: 14px;
  }
}

.p-footer__addresses {
  line-height: 2.27;
  white-space: nowrap;

  @include styleS((
    font-size: 14,
    margin-top: 36,
    margin-bottom: 24,
  ));

  @include sizeL {
    font-size: 11px;
    margin-top: 46px;
    margin-bottom: 32px;
  }

  @include sizeM {
    font-size: 10px;
  }
}

.p-footer__telFax {

}

.p-footer__telFaxLine {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  dd {
    display: block;
    padding-left: 0.4em;
  }

  dt {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;

    &::after {
      display: block;
      padding-left: 0.4em;
      content: ':';
    }
  }
}

.p-footerContactLink {
  position: relative;
}

.p-footerContactLink__inner {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  @include styleS((
    padding-top: 8,
    padding-bottom: 8,
  ));;

  @include sizeL {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.p-footerContactLink__label {
  display: block;
  text-transform: uppercase;
  position: relative;

  &::after {
    position: absolute;
    content: '';
    left: 0;
    bottom: -0.2em;
    width: 100%;
    height: 1px;
    background-color: #fff;
    transition: transform 0.4s $easeOutExpo;
    transform: scaleX(0);
    transform-origin: top left;
  }

  @include styleS((
    font-size: 12,
    margin-right: 12
  ));

  @include sizeL {
    font-size: 11px;
    margin-right: 12px;
  }
}

@include hover {
  .p-footerContactLink:hover {
    .p-footerContactLink__label::after {
      transform: scaleX(1);
    }
    .p-footerContactLink__circleArrow::after {
      transform: translateX(0%) rotate(45deg);
    }
  }
}

.p-footerContactLink__circleArrow {
  transition: transform 0.4s $easeOutExpo;

  @include styleS((
    width: 16,
    height: 16,
  ));
  @include sizeL {
    width: 16px;
    height: 16px;
  }
}

.p-footer__info {
  position: relative;
  order: 2;

  @include sizeL {
    order: 1;
  }
}

.p-footerNav {
  position: relative;
  order: 1;

  @include sizeL {
    order: 2;
    top: -12px;
  }

  @include sizeM {
    top: -10px;
  }
}

.p-footerNav__list {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: -1em;

  @include styleS((
    margin-bottom: 130,
  ));

  @include sizeL {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0;
    margin-left: 0;
  }
}

.p-footerNavItem {
  position: relative;

  @include styleS((
    margin-bottom: 8,
  ));

  @include sizeL {
    margin-left: 6px;
    margin-bottom: 0;

    &:first-child {
      margin-left: 0;
    }
  }

  @include sizeM {
    margin-left: 6px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.p-footerNavItem__inner {
  display: block;
  text-transform: uppercase;
  padding: 1em;

  @include sizeL {
    font-size: 11px;
    padding: 1em 0.8em;
  }
  @include sizeM {
    font-size: 10px;
    padding: 1em 0.5em;
  }
}

.p-footerNavItem__label {
  position: relative;
  display: block;
  white-space: nowrap;

  &::after {
    position: absolute;
    content: '';
    left: 0;
    bottom: -0.2em;
    width: 100%;
    height: 1px;
    background-color: #fff;
    transition: transform 0.4s $easeOutExpo;
    transform: scaleX(0);
    transform-origin: top left;
  }
}

@include hover {
  .p-footerNavItem:hover {
    .p-footerNavItem__label::after {
      transform: scaleX(1);
    }
  }
}

.p-footer__bottom {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  order: 3;
  width: 100%;

  @include styleS((
    margin-top: 160,
  ));

  @include sizeL {
    margin-top: 0;
    bottom: 0;
    width: auto;
    right: 40px;
    left: 40px;
    position: absolute;
  }
}

.p-footer__copyright {
  position: relative;
  margin-left: auto;

  @include styleS((
    font-size: 10,
  ));

  @include sizeL {
    font-size: 10px;
  }
}


.p-footerPrivacyLink {
  position: relative;


  @include styleS((
    font-size: 10,
  ));

  @include sizeL {
    font-size: 10px;
  }
}

.p-footerPrivacyLink__inner {
  display: block;
  text-transform: uppercase;

  @include styleS((
    padding-top: 6,
    padding-bottom: 6,
  ));
  @include sizeL {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

.p-footerPrivacyLink__label {
  position: relative;
  display: block;

  &::after {
    position: absolute;
    content: '';
    left: 0;
    bottom: -0.2em;
    width: 100%;
    height: 1px;
    background-color: #fff;
    transition: transform 0.4s $easeOutExpo;
    transform: scaleX(0);
    transform-origin: top left;
  }
}

@include hover {
  .p-footerPrivacyLink:hover {
    .p-footerPrivacyLink__label::after {
      transform: scaleX(1);
    }
  }
}

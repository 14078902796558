// ===============================
// Object
// ===============================

// component
.company_c-list {
  @include styleS((
    margin-top: 56,
  ));
  @include sizeL {
    margin-top: 56px;
  }

  dt {
    margin-bottom: 1em;

    @include styleS((
      font-size: 10,
      margin-top: 40
    ));
    @include sizeL {
      font-size: 10px;
      margin-top: 40px;
    }
  }

  dd {
    line-height: 2.5;
    font-weight: bold;

    @include styleS((
      font-size: 12,
    ));

    @include sizeL {
      line-height: 2;
      font-size: 15px;
    }
  }
}

.company_c-listTxt {
  line-height: 2.5;
  font-weight: bold;

  @include styleS((
    font-size: 12,
  ));

  @include sizeL {
    line-height: 2;
    font-size: 15px;
  }
}

.company_c-historyList {
  list-style: none;
}

.company_c-historyListItem {
  list-style: none;
  position: relative;
  padding-left: 3em;
  white-space: nowrap;
}

.company_c-historyList__month {
  @include absoluteTopLeft;
  display: block;
}



// Project

.company_p-main {
  position: relative;
  display: block;
}

.company_p-overview,
.company_p-history {
  @include styleS((
    margin-top: 90,
  ));

  @include sizeL {
    margin-top: 90px;
  }

  .c-commonSection__inner {
    max-width: 936 + 100 * 2px;
  }
}

.company_p-history__inner {
  @include sizeL {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
  }
}

.company_p-history {
  @include styleS((
    margin-top: 260,
  ));

  @include sizeL {
    margin-top: -80px;
  }
}

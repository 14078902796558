.p-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 11;
}

.p-header__logoMark {
  @include absoluteCenterH;
  transform: translateX(6.25%);

  @include styleS((
    width: 38,
    height: 36,
    top: 38
  ));

  @include sizeL {
    width: 38px;
    height: 36px;
    top: 38px;
  }

  svg {
    @include inner100Block;
    fill: $colorWhite;
    transition: fill 0.2s linear;
  }
}

.p-header__logoTxt {
  position: absolute;

  @include styleS((
    width: 76,
    height: 12,
    top: 50,
    left: 16
  ));

  @include sizeL {
    width: 76px;
    height: 12px;
    top: 50px;
    left: 35px;
  }

  svg {
    @include inner100Block;
    fill: $colorWhite;
    transition: fill 0.2s linear;
  }

}

.p-headerContactLink {
  position: absolute;
  display: none;

  @include sizeL {
    display: block;
    font-size: 10px;
    right: 70px;
    top: 40px;
  }
}

.p-headerContactLink__inner {
  display: block;
  text-transform: uppercase;

  @include sizeL {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.p-headerContactLink__label {
  display: block;
  position: relative;

  &::after {
    position: absolute;
    content: '';
    left: 0;
    bottom: -0.2em;
    width: 100%;
    height: 1px;
    background-color: #fff;
    transition: transform 0.4s $easeOutExpo;
    transform: scaleX(0);
    transform-origin: top left;
  }
}

@include hover {
  .p-headerContactLink:hover {
    .p-headerContactLink__label::after {
      transform: scaleX(1);
    }
  }
}
// ===============================
// Object
// ===============================

// component

.technology_c-section {
  position: relative;

  @include styleS((
    margin-top: 80,
  ));

  @include sizeL {
    @include absoluteCenterH;
    margin-top: 80px;
    margin-bottom: 80px;
    top: 0;
    visibility: hidden;
    transition: visibility 0.4s linear;

    &.is-current {
      transition: none;
      visibility: visible;
    }
  }

  @include sizeM {
    overflow: hidden;
  }

  &:first-child {
    margin-top: 0;
  }

  .c-commonTxtBox {

    @include styleS((
      margin-top: -70,
    ));

    @include sizeL {
      @include absoluteCenterVT;
      width: 390px;
      right: 180px;
      margin-top: 0;
    }

    @include sizeM {
      position: relative;
      width: 480px;
      right: auto;
      top: auto;
      transform: none;
      margin-top: -260px;
      float: right;
    }
  }

  .c-copy {
    @include sizeM {
      font-size: 18px;
    }
  }

  .c-description {
    position: relative;
    z-index: 2;

    a {
      color: $colorOrange;
      text-decoration: underline;
    }
  }

  @include sizeL {
    .c-smallTitle,
    .c-copy,
    .c-description {
      transition: opacity 0.4s linear,
                  transform 0.6s $easeOutExpo 0.4s;
      opacity: 0;
      transform: translateX(-10px);
    }

    &.is-current .c-smallTitle {
      opacity: 1;
      transform: translateX(0);
      transition-delay: 0.2s;
    }
    &.is-current .c-copy {
      opacity: 1;
      transform: translateX(0);
      transition-delay: 0.4s;
    }
    &.is-current .c-description {
      opacity: 1;
      transform: translateX(0);
      transition-delay: 0.8s;
    }
    &.is-current .technology_c-section__logo {
      opacity: 1;
      transition-delay: 1s;
    }
  }
}

.technology_c-section__logo {
  position: absolute;
  width: 40%;
  bottom: 0;
  transition: opacity 0.4s linear;

  @include styleS((
    right: 26
  ));

  @include sizeL {
    opacity: 0;
    bottom: auto;
    top: -18px;
    right: auto;
    width: 160px;
    left: 390 + 160px;
    transform: translateX(-100%);
  }

  @media screen and (min-width:1300px) {
    left: 390 + 220px;
  }

  @include sizeM {
    width: 120px;
    left: 490px !important;
    top: 66px;
    transform: translateX(-100%);
  }

  &::before {
    display: block;
    width: 100%;
    padding-top: 274 / 660 * 100%;
    content: '';

  }

  img {
    @include inner100Absolute;
    display: block !important;
    opacity: 1 !important;
  }
}


.technology_c-section__inner {
}

.technology_c-section__keyVisual {
  position: relative;
  opacity: 0.35;

  &::before {
    display: block;
    width: 100%;
    padding-top: 1164 / 1590 * 100%;
    content: '';
  }

  img {
    @include inner100Absolute;
    object-fit: contain;


    // html:not(.is-noWebGL) & {
    //   display: none;
    // }
  };



  @include sizeL {
    width: 795 / 936 * 100%;
    left: -225px;

    img {
      transition: opacity 0.4s linear,
                  transform 0.6s $easeOutExpo 0.4s !important;
      opacity: 0;
      transform: translateX(-40px);
    }

    .technology_c-section.is-current & {
      img {
        opacity: 1 !important;
        transform: translateX(0) !important;
        transition: opacity 0.4s linear 0s,
                    transform 1.2s $easeOutExpo 0s !important;
      }
    }
  }

  @include sizeM {
    width: 640 / 936 * 100%;
    left: -60px;
  }
}

.technology_c-section__visual {
  position: relative;
  width: 100%;

  &::before {
    display: block;
    width: 100%;
    padding-top: 100%;
    content: '';
  }

  img {
    @include inner100Absolute;
    object-fit: contain;

    html:not(.is-noWebGL) & {
      display: none;
    }
  };

  margin-top: -10%;

  @include sizeL {
    @include absoluteCenterV;
    width: 400px;
    height: 400px;
    right: -40px;
  }
  @media screen and (min-width:1300px) {
    width: 420px;
    height: 420px;
    right: -80px;
  }
  @include sizeM {
    bottom: auto;
    top: 0px;
    width: 320px;
    height: 320px;
    right: 40px;
  }
}

@include hover {
  .technology_c-section .c-description a:hover {
  }
}


// Project

.technology_p-main {
  position: relative;
  display: block;
}



// service
.technology_p-service {
  position: relative;

  @include styleS((
    margin-top: 160,
    padding-top: 60,
    padding-bottom: 40,
  ));

  @include sizeL {
    margin-top: 0;
    padding-top: 60px;
    padding-bottom: 100px,
  }

  .c-copyL {
    @include styleS((
      font-size: 20,
    ));
    @include sizeL {
      font-size: 26px;
    }
    @include sizeM {
      font-size: 26px;
    }
  }
}

.technology_p-service__inner {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;

  @include styleS((
    padding-left: 36,
    padding-right: 36,
  ));
  @include sizeL {
    padding-left: 100px;
    padding-right: 100px;
  }
}

.technology_p-service__visual {
  position: relative;
  width: 100%;

  &::before {
    display: block;
    width: 100%;
    padding-top: 100%;
    content: '';
  }

  img {
    @include inner100Absolute;
    object-fit: contain;

    html:not(.is-noWebGL) & {
      display: none;
    }
  };

  @include sizeL {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
}

.technology_p-service__link {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0 !important;
  transform: translateX(10%);
}

.technology_p-mainSections {
  position: relative;

  @include styleS((
    margin-top: 80,
  ));

  @include sizeL {
    margin-top: 256px;
    margin-bottom: 240px;
  }
}

.technology_p-mainSections__anchor {
  position: absolute;
  display: none;

  @include sizeL {
    display: block;
    z-index: -1;
    top: -80px;
    left: 0;
    width: 1px;
    height: 1px;
  }
}
// pageKV
.c-pageKV {
  position: relative;
  width: 100%;
  backface-visibility: hidden;
  min-height: 10px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 0.4s linear,
              visibility 0.4s linear;

  &.is-playing {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  .c-pageTitle {
    @include absoluteCenterHT;

    @include styleS((
      bottom: 46,
    ));

    @include sizeL {
      bottom: 46px;
    }
  }
}

.c-pageKV__dummyVisual {
  @include absoluteCenterVHT;
  backface-visibility: hidden;
  width: 100%;
  z-index: -2;

  &::before {
    display: block;
    widows: 100%;
    padding-top: 100%;
    content: '';
  }

  @include sizeL {
    width: 80%;
    max-width: 800px;
  }
}

.c-pageKV__visual {
  @include absoluteCenterVHT;
  backface-visibility: hidden;
  width: 100%;

  &::before {
    display: block;
    widows: 100%;
    padding-top: 100%;
    content: '';
  }

  img {
    @include inner100Absolute;
    display: block;
    opacity: 0.5;
  }

  @include sizeL {
    widows: 80%;
    max-width: 800px;
  }
}
.c-form {
  @include styleS((
    margin-top: 90,
    padding-left: 36,
    padding-right: 36,
  ));

  @include sizeL {
    margin-left: auto;
    margin-right: auto;
    margin-top: 90px;
    max-width: 936px;
    padding-left: 64px;
    padding-right: 64px;
  }
}

.c-form__lead {
  line-height: 2;
  font-weight: bold;
  @include absoluteCenterHT;
  position: relative;
  white-space: nowrap;


  @include styleS((
    font-size: 20,
    margin-bottom: 40
  ));

  @include sizeL {
    text-align: center;
    font-size: 20px;
    margin-bottom: 40px;
  }
}

.c-form__info {
  @include styleS((
    font-size: 12,
    margin-bottom: 44
  ));

  @include sizeL {
    font-size: 12px;
    margin-bottom: 44px;
  }
}

.c-form__infoItem {
  margin-top: 1em;

  &:first-child {
    margin-top: 0;
  }

  @include sizeL {
    text-align: center;
  }
}

.c-formTel {
  position: relative;
}

.c-formTel__inner {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 1em 0;
  background-color: $colorOrange;
  font-weight: bold;
  transition: background-color 0.2s linear, color 0.2s linear;

  @include styleS((
    font-size: 16,
    height: 52
  ));

  @include sizeL {
    font-size: 16px;
    height: 52px;
  }
}

.c-formTel__icon {
  fill: #fff;
  display: block;

  @include styleS((
    width: 18,
    height: 19,
    margin-right: 14
  ));

  @include sizeL {
    width: 18px;
    height: 19px;
    margin-right: 14px;
  }
}

.c-form__form {
  @include styleS((
    margin-top: 64,
  ));

  @include sizeL {
    margin-top: 90px;
  }
}

.c-form__list {

}

.c-formItem {
  @include styleS((
    margin-top: 52,
  ));

  @include sizeL {
    margin-top: 52px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &:first-child {
    margin-top: 0;
  }
}

.c-formItem__label {
  color: #7f7f7f;
  display: block;

  @include styleS((
    font-size: 10,
    margin-bottom: 12
  ));

  @include sizeL {
    font-size: 10px;
    margin-bottom: 0;
    min-width: 140px;
    white-space: nowrap;
  }

  label {
    display: block;
    padding-top: 20px;
  }
}

.c-formItem__contents {
  position: relative;
  border-bottom: 1px solid #fff;
  transition: border-bottom-color 0.1s linear;

  .c-formItem.is-error & {
    border-bottom-color: #ff0000;
  }

  input, textarea {
    display: block;
    font-size: 16px;
    color: #fff;
    border: none !important;
    background-color: transparent;
    margin: 0;
    width: 100%;
    padding: 0.4em;
  }

  @include sizeL {
    display: block;
    width: 100%;
  }
}

.c-formItem__errorMsg {
  position: absolute;
  left: 0;
  bottom: -1.8em;
  color: #ff0000;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.1s linear, opacity 0.1s linear;

  .c-formItem.is-error & {
    visibility: visible;
    opacity: 1;
  }

  @include styleS((
    font-size: 12,
  ));

  @include sizeL {
    font-size: 12px;
  }
}

.c-form__bottom {
  @include styleS((
    margin-top: 64,
  ));

  @include sizeL {
    margin-top: 88px;
  }
}

.c-form__bottomTxt {
  line-height: 2.5;
  margin-top: 2.5em;

  br {
    display: none;
  }

  @include styleS((
    font-size: 12,
  ));

  @include sizeL {
    text-align: center;
    font-size: 12px;

    br {
      display: block;
    }
  }

  a {
    display: inline;
    position: relative;
    color: $colorOrange;

    &::after {
      position: absolute;
      content: '';
      left: 0;
      bottom: -0.2em;
      width: 100%;
      height: 1px;
      background-color: $colorOrange;
      transition: transform 0.4s $easeOutExpo,
                  background-color 0.2s linear;
      transform: scaleX(0);
      transform-origin: top left;
    }
  }
}

@include hover {
  .c-form__bottomTxt a:hover {
    &::after {
      transform: scaleX(1);
    }
  }
}


.c-formSubmitButton {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  background-color: $colorOrange;
  color: $colorWhite;
  transition: background-color 0.2s linear, color 0.2s linear;

  @include styleS((
    margin-top: 64,
    width: 248
  ));

  @include sizeL {
    margin-top: 100px;
    width: 248px;
  }

  &:disabled {
    background-color: #4E4E4E;
  }
}

.c-formSubmitButton__inner {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.2em 0;

  @include styleS((
    font-size: 16,
    height: 52
  ));

  @include sizeL {
    font-size: 16px;
    height: 52px;
  }
}

@include hover {
  .c-formTel__inner:hover,
  .c-formSubmitButton:not(:disabled):hover {
    background-color: #fff;
    color: $colorOrange;
  }
}
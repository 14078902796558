@import "form";
@import "contactLink";
@import "pageKV";

// circleArrow
.c-circleArrow {
  display: block;
  position: relative;
  border-radius: 50%;
  border: 1px $colorWhite solid;
  transition: border-color 0.2s linear;

  &::after {
    @include absoluteCenterVH;
    content: '';
    display: block;
    border-top: 1px $colorWhite solid;
    border-right: 1px $colorWhite solid;
    width: 30%;
    height: 30%;
    transition: border-color 0.2s linear;
    transform: translateX(-20%) rotate(45deg);
  }

  .is-orangeBg & {
    border-color: $colorWhite;

    &::after {
      border-color: $colorWhite;
    }
  }
}

// circleArrowLink
.c-circleArrowLink {
  position: relative;
}

.c-circleArrowLink__inner {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  @include sizeL {
    height: 22px;
  }
}

.c-circleArrowLink__label {
  color: $colorOrange;
  text-transform: uppercase;
  margin-right: 1.4em;
  transition: color 0.2s linear;
  position: relative;
  display: block;

  .is-orangeBg & {
    color: $colorWhite;

    &::after {
      background-color: $colorWhite;
    }
  }

  @include styleS((
    font-size: 10,
  ));

  @include sizeL {
    font-size: 10px;
  }

  &::after {
    position: absolute;
    content: '';
    left: 0;
    bottom: -0.2em;
    width: 100%;
    height: 1px;
    background-color: $colorOrange;
    transition: transform 0.4s $easeOutExpo,
                background-color 0.2s linear;
    transform: scaleX(0);
    transform-origin: top left;
  }
}

.c-circleArrowLink__circleArrow {
  border-color: $colorOrange;

  &::after {
    border-color: $colorOrange;
    transition: border-color 0.2s linear,
                transform 0.4s $easeOutExpo;
  }

  @include styleS((
    min-width: 22,
    width: 22,
    height: 22,
  ));

  @include sizeL {
    min-width: 22px;
    width: 22px;
    height: 22px;
  }
}

@include hover {
  .c-circleArrowLink:hover {
    .c-circleArrowLink__label::after {
      transform: scaleX(1);
    }
    .c-circleArrowLink__circleArrow::after {
      transform: translateX(0%) rotate(45deg);
    }
  }
}


// simplePage
.c-simplePageSection {
  position: relative;

  @include styleS((
    padding-top: 144,
  ));

  @include sizeL {
    padding-top: 164px;
  }
}

// pageTitle {
.c-pageTitle {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.c-pageTitle__label {
  color: $colorOrange;
  text-transform: uppercase;
  display: block;
  order: 2;

  @include styleS((
    font-size: 12,
  ));

  @include sizeL {
    font-size: 12px;
  }
}

.c-pageTitle__labelJa {
  display: block;
  order: 1;
  white-space: nowrap;
  letter-spacing: 0.3em !important;

  @include styleS((
    font-size: 12,
    margin-bottom: 24
  ));

  @include sizeL {
    font-size: 12px;
    margin-bottom: 32px;
  }
}



// smallTitle
.c-smallTitle {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.c-smallTitle__label {
  color: $colorOrange;
  text-transform: uppercase;
  display: block;
  margin-right: 0.8em;

  @include styleS((
    font-size: 12,
  ));

  @include sizeL {
    font-size: 12px;
  }
}

.c-smallTitle__labelJa {
  display: block;

  @include styleS((
    font-size: 12,
  ));

  @include sizeL {
    font-size: 12px;
  }
}

// commonSection
.c-commonSection {
  position: relative;
}

.c-commonSection__inner {
  position: relative;
  max-width: 936 + 100 * 2px;
  margin-left: auto;
  margin-right: auto;

  @include styleS((
    padding-left: 36,
    padding-right: 36,
  ));

  @include sizeL {
    padding-left: 100px;
    padding-right: 100px;
  }
  @include sizeM {
    padding-left: 60px;
    padding-right: 60px;
  }
}



// commonTxtBox
.c-commonTxtBox {
  position: relative;

  &.is-center {
    text-align: center;

    .c-commonTxtBox__inner {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: center;
    }

    .c-smallTitle {
      justify-content: center;
    }
  }

  .c-circleArrowLink {
    @include styleS((
      margin-top: 46,
    ));
    @include sizeL {
      margin-top: 46px;
    }
  }

}

.c-commonTxtBox__inner {
  position: relative;

  @include styleS((
    margin-top: 46,
  ));

  @include sizeL {
    margin-top: 46px;
  }

  .c-description {
    @include styleS((
      margin-top: 46,
    ));
    @include sizeL {
      margin-top: 46px;
    }
  }
}

// description
.c-description {
  line-height: 2.5;
  margin-top: #{(2.5 - 1) * -0.5em};

  @include styleS((
    font-size: 12,
  ));

  @include sizeL {
    font-size: 12px;
  }
}



// copy
.c-copy {
  position: relative;
  line-height: 2;
  font-weight: bold;
  margin-top: -0.5em;
  margin-bottom: -0.5em;
  white-space: nowrap;

  @include styleS((
    font-size: 20,
  ));

  @include sizeL {
    font-size: 20px;
  }
}

// copyL
.c-copyL {
  position: relative;
  line-height: 2;
  font-weight: bold;
  margin-top: -0.5em;
  margin-bottom: -0.5em;
  white-space: nowrap;

  @include styleS((
    font-size: 26,
  ));

  @include sizeL {
    font-size: 26px;
  }
  @include sizeM {
    font-size: 22px;
  }
}




// numberTxtBox
.c-numberTxtBox {
  position: relative;
  backface-visibility: visible;
  z-index: 2;

  @include styleS((
    margin-top: 64,
  ));
  @include sizeL {
    margin-top: 72px;
  }

  .c-description {
    position: relative;
    backface-visibility: hidden;

    @include styleS((
      margin-top: 28,
    ));
    @include sizeL {
      margin-top: 32px;
    }
  }

  &.is-onScreenItem {
    transform: translateX(-6%);
    opacity: 0;

    &.is-onScreen {
      opacity: 1 !important;
      transform: translateX(0) translateZ(0) !important;
      transition: opacity 0.4s linear,
                  transform 1.2s $easeOutExpo;
    }
  }
}

.c-numberTxtBox__title {
  line-height: 1.73;
  white-space: nowrap;
  font-weight: bold;
  position: relative;
  backface-visibility: hidden;

  @include styleS((
    padding-left: 86,
    font-size: 15,
  ));

  @include sizeL {
    padding-left: 112px;
    line-height: 2.1;
    font-size: 18px;
  }
}

.c-numberTxtBox__num {
  @include absoluteTopLeft;
  color: $colorOrange;
  letter-spacing: 0 !important;
  font-family: Roboto, sans-serif !important;
  font-weight: 100;
  backface-visibility: hidden;

  @include styleS((
    font-size: 58,
  ));

  @include sizeL {
    font-size: 80px;
  }
}

// ===============================
// Object
// ===============================

// component



// Project

.service_p-main {
  position: relative;
  display: block;
}



// .customHome
.service_p-customHome {
  position: relative;

  @include styleS((
    padding-top: 140,
    padding-bottom: 0,
  ));

  @include sizeL {
    padding-top: 180px;
    padding-bottom: 60px;
  }

  .c-commonTxtBox {
    @include styleS((
      padding-top: 50,
    ));

    @include sizeL {
      position: absolute;
      width: 446px;
      padding-top: 0;
      right: 100px;
      top: 0;
    }
  }
}

.service_p-customHome__inner {
  @include sizeL {
    padding-top: 80px;
  }
}

.service_p-customHome__iegaoWrapper {
  position: relative;
  z-index: 2;
  width: 100%;
}

.service_p-customHome__iegao {
  position: absolute;
  width: 230 / 340 * 100%;

  &::before {
    display: block;
    width: 100%;
    padding-top: 179 / 350 * 100%;
    content: '';
  }

  img {
    @include inner100Absolute;
    object-fit: contain;

    // html:not(.is-noWebGL) & {
    //   display: none;
    // }
  };

  @include styleS((
    right: -8,
    top: -60
  ));

  @include styleS((
    width: 230,
  ));

  @include sizeL {
    width: 350px;
    height: 179px;
    margin-bottom: 40px;
    position: relative;
    right: auto;
    top: auto;
  }
}


.service_p-customHome__visual {
  position: relative;
  width: 304 / 340 * 100%;
  opacity: 0.35;

  @include styleS((
    margin-left: -20,
  ));

  @include sizeL {
    width: 760 / 936 * 100%;
    margin-left: -180px;
  }
  @include sizeM {
    margin-left: -120px;
  }

  &::before {
    display: block;
    width: 100%;
    padding-top: 894 / 1160 * 100%;
    content: '';
  }

  img {
    @include inner100Absolute;
    object-fit: contain;

    // html:not(.is-noWebGL) & {
    //   display: none;
    // }
  };
}



// iegao
.service_p-iegao {
  position: relative;

  .c-copy {
    position: relative;
    z-index: 2;
  }

  .c-commonTxtBox {
    width: 100%;
  }
}

.service_p-iegao__inner {
  @include styleS((
    padding-top: 150,
  ));

  @include sizeL {
    padding-top: 140px;

  }
}


.service_p-iegao__spacer {
  display: none;

  @include sizeL {
    position: absolute;
    display: block;
    width: 100%;
    top: 0;
    max-width: 1136px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: -1;

    &::before {
      display: block;
      width: 100%;
      padding-top: 60 / 100 * 100%;
      content: '';
    }
  }
}

.service_p-iegao__inner {
  @include sizeL {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

.service_p-iegao__visual {
  position: relative;
  width: 284 / 340 * 100%;
  opacity: 0.35;

  @include styleS((
    margin-top: -50,
    margin-left: 80,
    margin-bottom: -20,
  ));

  @include sizeL {
    float: none;
    width: 580 / 936 * 100%;
    margin-left: 80 / 936 * 100%;
    margin-right: 0;
    margin-top: -60px;
    margin-bottom: -120px;
  }

  &::before {
    display: block;
    width: 100%;
    padding-top: 894 / 1160 * 100%;
    content: '';
  }

  img {
    @include inner100Absolute;
    object-fit: contain;

    // html:not(.is-noWebGL) & {
    //   display: none;
    // }
  };
}

.service_p-iegao5Features {
  position: relative;

  @include sizeL {
    position: relative;
    width: 446px;
    // position: absolute;
    // margin-top: 35%;
  }
}

.service_p-iegao5Features__title {
  color: $colorOrange;
  font-weight: bold;
  text-transform: uppercase;

  @include styleS((
    font-size: 40,
    margin-bottom: 90
  ));

  @include sizeL {
    font-size: 40px;
    margin-bottom: 90px;
  }
}

.service_p-iegao5Features__subTitle {
  margin-top: 1em;
  display: block;
  color: #fff;

  @include styleS((
    font-size: 15,
  ));

  @include sizeL {
    font-size: 15px;
  }
}



// service
.service_p-technology {
  position: relative;

  @include styleS((
    padding-top: 156,
    padding-bottom: 0,
  ));

  @include sizeL {
    padding-top: 256px;
    padding-bottom: 0,
  }

  .c-copy {
    @include styleS((
      font-size: 15,
    ));

    @include sizeL {
      font-size: 20px;
    }
  }

  .c-description {
    text-align: left;

    @include sizeL {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        width: 370px;
      }

    }
  }
}

.service_p-technology__inner {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;

  @include styleS((
    padding-left: 36,
    padding-right: 36,
  ));
  @include sizeL {
    padding-left: 100px;
    padding-right: 100px;
  }
}

.service_p-technology__visual {
  position: relative;
  width: 100%;

  &::before {
    display: block;
    width: 100%;
    padding-top: 100%;
    content: '';
  }

  img {
    @include inner100Absolute;
    object-fit: contain;

    html:not(.is-noWebGL) & {
      display: none;
    }
  };

  @include sizeL {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
}

.service_p-technology__link {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0 !important;
  transform: translateX(6%);
}



.service_p-technology__copy {
  @include absoluteCenterVHT;
}
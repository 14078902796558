// ===============================
// Object
// ===============================

// component



// Project

.about_p-main {
  position: relative;
  display: block;
}

.about_p-kvCopy {
  @include absoluteCenterVHT;
  text-align: center;
  line-height: 2;
  font-weight: bold;
  white-space: nowrap;

  @include styleS((
    font-size: 26,
  ));

  @include sizeL {
    font-size: 26px;
  }
}


.about_p-copy {
  position: relative;

  @include styleS((
    padding-top: 100,
    padding-bottom: 164
  ));

  @include sizeL {
    padding-top: 140px;
    padding-bottom: 254px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }

  .c-copyL {
    margin-left: auto;
    margin-right: auto;
    line-height: 2.8;
    white-space: nowrap;
    letter-spacing: 0 !important;

    @include styleS((
      font-size: 18,
    ));

    @include sizeL {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      line-height: 2;
      font-size: 26px;
      letter-spacing: 0.2em !important;
      width: 936px;
    }
    @include sizeM {
      font-size: 22px;
      width: 760px;
    }
  }
}


// vision
.about_p-vision {
  position: relative;
  padding-top: 0;

  @include styleS((
    padding-bottom: 64,
  ));

  @include sizeL {
    padding-top: 60px;
    padding-bottom: 100px,
  }

  .c-copyL {
    @include styleS((
      font-size: 20,
    ));
    @include sizeL {
      font-size: 26px;
    }
    @include sizeM {
      font-size: 22px;
    }
  }

  .c-commonTxtBox {
    @include sizeL {
      width: 50%;
      padding-left: 5%;
    }
  }

  .c-description {
    @include sizeL {
      max-width: 360px;
    }
  }
}

.about_p-vision__inner {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;

  @include styleS((
    padding-left: 36,
    padding-right: 36,
  ));
  @include sizeL {
    padding-left: 100px;
    padding-right: 100px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }
  @include sizeM {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.about_p-vision__visual {
  position: relative;
  width: 100%;

  &::before {
    display: block;
    width: 100%;
    padding-top: 100%;
    content: '';
  }

  img {
    @include inner100Absolute;
    object-fit: contain;

    html:not(.is-noWebGL) & {
      display: none;
    }
  };

  @include sizeL {
    min-width: 50%;
    width: 50%;
    transform: translateX(-20%);
  }
}

// message
.about_p-message {
  position: relative;
  padding-top: 0;

  @include styleS((
    padding-top: 100,
    padding-bottom: 40,
  ));

  @include sizeL {
    padding-top: 100px;
    padding-bottom: 100px,
  }

  .c-copyL {
    @include styleS((
      font-size: 20,
    ));
    @include sizeL {
      font-size: 26px;
    }
    @include sizeM {
      font-size: 20px;
    }
  }
}

.about_p-message__visual {
  position: relative;
  width: 100%;

  &::before {
    display: block;
    width: 100%;
    padding-top: 574 / 597 * 100%;
    content: '';
  }

  img {
    @include inner100Absolute;

    html:not(.is-noWebGL) & {
      // display: none;
    }
  };

  @include styleS((
    margin-bottom: 90,
  ));

  @include sizeL {
    margin-bottom: 0;
    // min-width: calc(100px + 50%);
    width: calc(100px + 50%);
    margin-right: -100px;
    margin-top: 10%;
  }

  @include sizeM {
    margin-top: 16%;
    min-width: calc(60px + 46%);
    width: calc(60px + 46%);
    margin-right: -60px;
  }
}

.about_p-message__visual2 {
  position: relative;
  display: none;

  @include sizeL {
    display: block;
    width: 26%;
    transform: translate(-24%, -50%);
  }

  @include sizeM {
    transform: translate(-10%, -36%);
  }

  &::before {
    display: block;
    width: 100%;
    padding-top: 366 / 258 * 100%;
    content: '';
  }

  img {
    @include inner100Absolute;

    html:not(.is-noWebGL) & {
      // display: none;
    }
  };
}

.about_p-message__firstBlock {
  @include styleS((
    padding-bottom: 90,
  ));

  @include sizeL {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 120px;
  }
  @include sizeM {
    padding-bottom: 180px;
  }

  .c-description {
    @include sizeL {
      max-width: 360px;
    }
    @include sizeM {
      max-width: 320px;
    }
  }
}

.about_p-message__secondBlock {
  @include sizeL {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  .c-commonTxtBox {
    @include sizeL {
      width: 70%;
      padding-right: 6%;
    }
  }
}

.about_p-messageBottom {
  @include styleS((
    margin-top: 70,
  ));

  @include sizeL {
    margin-top: 46px;
  }
}

.about_p-messageBottom__title {
  @include styleS((
    font-size: 10,
     margin-bottom: 14
  ));

  @include sizeL {
    font-size: 10px;
    margin-bottom: 14px;
  }
}

.about_p-messageBottom__name {
  @include styleS((
    font-size: 15,
  ));

  @include sizeL {
    font-size: 15px;
  }
}




// member
.about_p-member {
  position: relative;
  padding-top: 0;

  @include styleS((
    padding-top: 100,
    padding-bottom: 164,
  ));

  @include sizeL {
    padding-top: 100px;
    padding-bottom: 140px,
  }

  .c-copyL {
    @include styleS((
      font-size: 20,
    ));
    @include sizeL {
      font-size: 26px;
    }
    @include sizeM {
      font-size: 26px;
    }
  }
}

.about_p-member__head {
  position: relative;
}

.about_p-member__message {
  @include absoluteTopRight;
  color: $colorOrange;

  @include styleS((
    font-size: 10,
    top: 2
  ));

  @include sizeL {
    font-size: 12px;
    line-height: 1;
  }
}


.about_p-member__list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include styleS((
    padding-top: 70,
  ));

  @include sizeL {
    padding-top: 100px;
    justify-content: center;
  }
}

.about_p-memberItem {
  position: relative;
  width: 43%;

  @include styleS((
    padding-bottom: 30,
    margin-bottom: 30
  ));
  @include sizeL {
    padding-bottom: 6%;
    margin-bottom: 6%;
  }


  &:nth-child(even) {
    transform: translateY(114 / $spDesignWidthBasis * 100vw);
  }

  &.is-onScreenItem {
    transform: translateY(20 / $spDesignWidthBasis * 100vw);

    &:nth-child(even) {
      transform: translateY(134 / $spDesignWidthBasis * 100vw);
    }

    &.is-onScreen {
      transition: opacity 0.4s linear,
                visibility 0.4s linear,
                transform 0.6s $easeOutExpo;
      transform: translateY(0);

      &:nth-child(even) {
        transform: translateY(114 / $spDesignWidthBasis * 100vw);
      }
    }
  }

  @include sizeL {
    width: 22%;
    margin-left: 4%;

    &:nth-child(even) {
      transform: none;
    }

    &:nth-child(7n + 2),
    &:nth-child(7n + 4),
    &:nth-child(7n + 6) {
      transform: translateY(6%);
    }

    &:nth-child(7n + 1) {
      margin-left: 10%;
    }
    &:nth-child(7n + 3) {
      margin-right: 10%;
    }
    &:nth-child(7n + 4) {
      margin-left: 0;
    }

    &.is-onScreenItem {
      &:nth-child(even) {
        transform: none;
      }

      &:nth-child(7n + 1),
      &:nth-child(7n + 3),
      &:nth-child(7n + 5),
      &:nth-child(7n + 7) {
        transform: translateY(6%);
      }

      &:nth-child(7n + 2),
      &:nth-child(7n + 4),
      &:nth-child(7n + 6) {
        transform: translateY(12%);
      }

      &.is-onScreen {
        transition: opacity 0.4s linear,
                  visibility 0.4s linear,
                  transform 0.6s $easeOutExpo;

        &:nth-child(7n + 1),
        &:nth-child(7n + 3),
        &:nth-child(7n + 5),
        &:nth-child(7n + 7) {
          transform: translateY(0);
        }

        &:nth-child(7n + 2),
        &:nth-child(7n + 4),
        &:nth-child(7n + 6) {
          transform: translateY(6%);
        }
      }
    }
  };

  @include sizeM {
    width: 30%;
    margin-left: 5% !important;
    transform: none !important;
    margin-right: 0 !important;

    &:nth-child(7n + 2),
    &:nth-child(7n + 4),
    &:nth-child(7n + 6) {
      transform: none;
    }

    &:nth-child(3n + 1) {
      margin-left: 0 !important;
    }

    &:nth-child(3n + 2) {
      transform: translateY(6%);
    }

    &.is-onScreenItem {
      &:nth-child(7n + 2),
      &:nth-child(7n + 4),
      &:nth-child(7n + 6) {
        transform: none;
      }
      &:nth-child(7n + 1),
      &:nth-child(7n + 3),
      &:nth-child(7n + 5),
      &:nth-child(7n + 7) {
        transform: none;
      }

      &:nth-child(3n + 1),
      &:nth-child(3n + 3) {
        transform: translateY(6%) !important;
      }

      &:nth-child(3n + 2) {
        transform: translateY(12%) !important;
      }

      &.is-onScreen {
        transition: opacity 0.4s linear,
                  visibility 0.4s linear,
                  transform 0.6s $easeOutExpo;

        &:nth-child(3n + 1),
        &:nth-child(3n + 3) {
          transform: translateY(0) !important;
        }

        &:nth-child(3n + 2) {
          transform: translateY(6%) !important;
        }
      }
    }
  }


}

.about_p-memberItem__img {
  position: relative;
  overflow: hidden;
  width: 100%;
  transition: opacity 0.2s linear;
  opacity: 0.8;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 230 / 144 * 100%;
  }

  img {
    @include inner100Absolute;
    object-fit: cover;
    transition: transform 2s $easeOutExpo,
                opacity 0.4s linear !important;
    object-fit: cover;
  }

  @include styleS((
    margin-bottom: 10
  ));

  @include sizeL {
    margin-bottom: 10px;
  }
}

.about_p-memberItem__title {
  transition: color 0.2s linear;

  @include styleS((
    font-size: 11,
    margin-bottom: 10
  ));

  @include sizeL {
    font-size: 11px;
    margin-bottom: 10px;
  }
}

.about_p-memberItem__name {
  transition: color 0.2s linear;

  @include styleS((
    font-size: 15,
    margin-bottom: 20
  ));

  @include sizeL {
    font-size: 15px;
    margin-bottom: 20px;
  }
}

.about_p-memberItem__description {
  line-height: 1.7;
  transition: color 0.2s linear;

  @include styleS((
    font-size: 10,
  ));

  @include sizeL {
    font-size: 10px;
  }
}

@include hover {
  .about_p-memberItem:hover {
    .about_p-memberItem__img {
      transition-duration: 0.1s;
      opacity: 1;

      img {
        transform: scale(1.1);
      }
    }
    .about_p-memberItem__title,
    .about_p-memberItem__name,
    .about_p-memberItem__description {
      color: $colorOrange;
      transition-duration: 0.1s;
    }
  }
}


// service
.about_p-service {
  position: relative;

  @include styleS((
    padding-top: 60,
    padding-bottom: 40,
  ));

  @include sizeL {
    padding-top: 60px;
    padding-bottom: 100px,
  }

  .c-copyL {
    @include styleS((
      font-size: 20,
    ));
    @include sizeL {
      font-size: 26px;
    }
    @include sizeM {
      font-size: 26px;
    }
  }
}

.about_p-service__inner {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;

  @include styleS((
    padding-left: 36,
    padding-right: 36,
  ));
  @include sizeL {
    padding-left: 100px;
    padding-right: 100px;
  }
}

.about_p-service__visual {
  position: relative;
  width: 100%;

  &::before {
    display: block;
    width: 100%;
    padding-top: 100%;
    content: '';
  }

  img {
    @include inner100Absolute;
    object-fit: contain;

    html:not(.is-noWebGL) & {
      display: none;
    }
  };

  @include sizeL {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
}

.about_p-service__link {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0 !important;
  transform: translateX(10%);
}
.p-menuButton {
  position: fixed;
  z-index: 11;

  @include styleS((
    width: 36,
    height: 36,
    right: 16 - 8,
    top: 52 - 13
  ));

  @include sizeL {
    width: 36px;
    height: 36px;
    right: #{36 - 8px};
    top: #{50 - 13px};
  }

  &::before, &::after {
    @include absoluteCenterVH;
    content: '';
    background-color: #fff;

    @include styleS((
      width: 20,
      height: 2,
      border-radius: 1
    ));

    @include sizeL {
      width: 20px;
      height: 2px;
      border-radius: 1px;
    }
  }

  &::before {
    transform: translateY(-150%);
  }
  &::after {
    transform: translateY(150%);
  }
}


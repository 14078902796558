// onScreenImg
.is-onScreenImg img {
  opacity: 0;
}

.is-onScreenImg.is-onScreenImgLoaded.is-onScreen img,
:not(.is-onScreenImg).is-onScreenImgLoaded img {
  opacity: 1;
  transition: opacity 0.4s linear;
}


// onScreenTxt
.is-onScreenTxt {
  display: flex;
  flex-wrap: nowrap;

  span.is-hidden {
    position: relative;
    display: block;
    opacity: 0;
    @include absoluteTopLeft;
  }
  span[aria-hidden="true"] {
    opacity: 0;
    position: relative;
    display: block;

    &:after {
      content: '';
      display: none;
      height: 90%;
      top: 0;
      width: 0.1em;
      background-color: $colorBlack;
      position: absolute;
      right: -0.2em;
    }
  }
}

@for $var from 1 to 20 {
  .is-onScreenTxt.is-onScreen.is-charIndex--#{$var} {
    span[aria-hidden="true"] {
      &:nth-child(#{$var}):after {
        display: block;
      }

      &:nth-child(-n + #{$var}) {
        opacity: 1;
      }
    }
  }
}
// ===============================
// Object
// ===============================

// component

// topicsItem
.topics_c-item {
  position: relative;

  @include styleS((
    margin-top: 90
  ));


  &:first-child {
    margin-top: 0
  }

  @include sizeL {
    width: 300px;
    margin-top: 106px;

    &:first-child,&:nth-child(2) {
      margin-top: 0
    }

    &:nth-child(even) {
      transform: translateY(106px)
    }

  }
}

.topics_c-item__inner {
  position: relative;
  display: block;
}

.topics_c-item__thumb {
  position: relative;
  background-color: rgba($colorWhite, 0.1);
  overflow: hidden;

  width: 100%;
  margin-right: 0;

  @include styleS((
    margin-bottom: 24,
  ));

  @include sizeL {
    margin-bottom: 16px;
  }


  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 7 / 10 * 100%;
  }

  img {
    @include inner100Absolute;
    transition: transform 2s $easeOutExpo,
                opacity 0.4s linear !important;
    object-fit: cover;
  }
}

.topics_c-item__contents {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.topics_c-item__date {
  color: $colorWhite;
  margin-bottom: 1em;

  @include styleS((
    font-size: 10
  ));

  @include sizeL {
    font-size: 10px;
  }
}

.topics_c-item__title {
  color: $colorWhite;
  font-weight: 700;
  line-height: 1.6;
  margin-bottom: 0.6em;
  transition: color 0.2s linear;
  box-decoration-break: clone;
  &::after {
    position: absolute;
    content: '';
    left: 0;
    bottom: -0.2em;
    width: 100%;
    height: 1px;
    background-color: #fff;
    transition: transform 0.4s $easeOutExpo;
    transform: scaleX(0);
    transform-origin: top left;
  }

  @include styleS((
    font-size: 14,
  ));

  @include sizeL {
    font-size: 14px;
  }
}

.topics_c-item__description {
  // color: #b0b0b0; // gray 70%
  color: rgba($colorWhite, 0.7); // gray 70%
  line-height: 1.8;
  margin-bottom: 1em;

  @include styleS((
    font-size: 10,
  ));

  @include sizeL {
    display: block;
    font-size: 10px;
  }
}

.topics_c-item__category {
  color: $colorWhite;
  background-color: $colorOrange;
  padding: 0.6em 1em;
  transition: background-color 0.2s linear,
              color 0.2s linear;

  .is-orangeBg & {
    color: $colorOrange;
    background-color: $colorWhite;
  }

  @include styleS((
    font-size: 10,
  ));

  @include sizeL {
    font-size: 10px;
  }
}

@include hover {
  .topics_c-item:hover {
    .topics_c-item__thumb img {
      transform: scale(1.1);
    }
    .topics_c-item__title {
      color: $colorOrange;
      transition-duration: 0.1s;

      .is-orangeBg & {
        color: $colorWhite;
      }
    }
  }
}



// Project

.topics_p-main {
  position: relative;
  display: block;

  .c-smallTitle {
    justify-content: center;
  }
}

.topics_p-categoryNav {
  position: relative;

  @include styleS((
    padding-left: 10,
    padding-right: 10,
    margin-top: 74
  ));

  @include sizeL {
    padding: 0;
    margin-top: 116px;
  }
}

.topics_p-categoryNav__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.topics_p-categoryNavItem {
  position: relative;
  display: block;

  @include styleS((
    margin-left: 5,
    margin-right: 5,
    margin-top: 10,
  ));

  @include sizeL {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
  }
}

.topics_p-categoryNavItem__inner {
  display: block;
  color: $colorWhite;
  background-color: $colorOrange;
  letter-spacing: 0.08em !important;
  padding: 0.6em 0.5em;
  transition: background-color 0.2s linear, color 0.2s linear;

  @include styleS((
    font-size: 12,
  ));

  @include sizeL {
    font-size: 12px;
  }

  .topics_p-categoryNavItem.is-current & {
    background-color: $colorWhite;
    color: $colorOrange;
  }
}

@include hover {
  .topics_p-categoryNavItem:hover .topics_p-categoryNavItem__inner {
    background-color: $colorWhite;
    color: $colorOrange;
    transition-duration: 0.1s;
  }
}

.topics_p-list {
  position: relative;

  @include styleS((
    padding-left: 36,
    padding-right: 36,
    padding-top: 74
  ));

  @include sizeL {
    margin-left: auto;
    margin-right: auto;
    padding-top: 142px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: 84px;
    padding-right: 84px;
    max-width: 936px;
  }

  @include sizeM {
    padding-left: 60px;
    padding-right: 60px;
  }
}



.topics_p-pagination {
  position: relative;

  @include styleS((
    margin-top: 100,
    margin-left: 36,
    margin-right: 36,
    height: 32
  ));

  @include sizeL {
    margin-top: 242px;
    max-width: #{456px + 84 * 2};
    padding-left: 84px;
    padding-right: 84px;
    margin-left: auto;
    margin-right: auto;
  }
}

.topics_p-paginationNavButton__circleArrow {

}

.topics_p-paginationNavButton {
  @include absoluteCenterV;
  z-index: 2;
}

.topics_p-paginationNavButton__inner {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  padding: 0.4em 0;
}

.topics_p-paginationNavButton__label {
  display: block;
  text-transform: uppercase;
  color: #fff;
  position: relative;
  padding-bottom: 0.1em;

  @include styleS((
    font-size: 11,
  ));
  @include sizeL {
    font-size: 11px;
  }

  &::after {
    position: absolute;
    content: '';
    left: 0;
    bottom: -0.2em;
    width: 100%;
    height: 1px;
    background-color: #fff;
    transition: transform 0.4s $easeOutExpo,
                background-color 0.2s linear;
    transform: scaleX(0);
    transform-origin: top left;
  }
}

.topics_p-paginationNavButton__circleArrow {
  @include styleS((
    width: 16,
    height: 16,
    margin-left: 10,
  ));

  @include sizeL {
    width: 16px;
    height: 16px;
    margin-left: 10px;
  }

  &::after {
    transition: transform 0.4s $easeOutExpo,
                background-color 0.2s linear;
  }
}

.topics_p-paginationNavButton--prev {
  left: 0;

  @include sizeL {
    left: 84px;
  }
  @include sizeM {
    left: 60px;
  }

  .topics_p-paginationNavButton__circleArrow {
    order: 1;
    transform: scaleX(-1);
    margin-left: 0;

    @include styleS((
      margin-right: 10,
    ));

    @include sizeL {
      margin-right: 10px;
    }
  }

  .topics_p-paginationNavButton__label {
    order: 2;
  }
}

.topics_p-paginationNavButton--next {
  right: 0;

  @include sizeL {
    right: 84px;
  }
  @include sizeM {
    right: 60px;
  }
}

@include hover {
  .topics_p-paginationNavButton__inner:hover {
    .topics_p-paginationNavButton__label::after {
      transform: scaleX(1);
    }
    .topics_p-paginationNavButton__circleArrow::after {
      transform: translateX(0%) rotate(45deg);
    }
  }
}

.topics_p-paginationNums {
  position: relative;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.topics_p-paginationNumItem {
  position: relative;

  @include styleS((
    margin-left: 4,
    margin-right: 4,
    font-size: 12
  ));
}

.topics_p-paginationNumItem__inner {
  display: block;
  color: #767676;
  padding: 0.5em 0.2em;
  position: relative;
  z-index: 2;
  transition: color 0.2s linear;

  .topics_p-paginationNumItem.is-current & {
    color: #fff;
  }
}

@include hover {
  a.topics_p-paginationNumItem__inner:hover {
    color: #fff;
    transition-duration: 0.1s;
  }
}

.topics_p-paginationNumItem--num + .topics_p-paginationNumItem--num {
  &::before  {
    display: block;
    color: #767676;
    padding: 0.5em 0;
    @include absoluteCenterV;
    left: -0.6em;
    content: '/'
  }
}

.topics_p-paginationLinkToList {
  @include absoluteCenterVHT;
  height: 100%;
}

.topics_p-paginationLinkToList__inner {
  position: relative;
  padding: 0.4em 0 0.6em;
  color: #fff;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1;

  @include styleS((
    font-size: 12,
  ));

  @include sizeL {
    font-size: 12px;
  }
}

.topics_p-paginationLinkToList__label {
  position: relative;

  &::after {
    position: absolute;
    content: '';
    left: 0;
    bottom: -0.3em;
    width: 100%;
    height: 1px;
    background-color: #fff;
    transition: transform 0.4s $easeOutExpo,
                background-color 0.2s linear;
    transform: scaleX(0);
    transform-origin: top left;
  }
}

@include hover {
  .topics_p-paginationLinkToList:hover {
    .topics_p-paginationLinkToList__label::after {
      transform: scaleX(1);
    }
  }
}




//
// detail
//

.topicsDetail_p-main {
  .topics_p-pagination {
    @include sizeL {
      margin-top: 100px;
    }
  }

  .c-smallTitle {
    justify-content: center;
  }
}


.topicsDetail_p-article {
  @include styleS((
    margin-top: 98,
    padding-left: 16,
    padding-right: 16
  ));

  @include sizeL {
    margin-top: 84px;
    margin-left: auto;
    margin-right: auto;
    max-width: #{600 + 60 * 2px};
    padding-left: 60px;
    padding-right: 60px;
  }
}

.topicsDetail_p-article__img {
  width: 100%;
  position: relative;

  img {
    width: 100%;
    height: auto;
  }
}

.topicsDetail_p-article__contents {
  position: relative;

  @include styleS((
    padding-left: 20,
    padding-right: 20,
    margin-top: 70,
    padding-top: 10
  ));

  @include sizeL {
    padding-left: 0;
    padding-right: 0;
    margin-top: 48px;
    padding-top: 10px;
  }
}

.topicsDetail_p-article__date {
  color: $colorWhite;

  @include styleS((
    font-size: 12,
    margin-bottom: 16
  ));

  @include sizeL {
    font-size: 12px;
    margin-bottom: 16px;
  }
}

.topicsDetail_p-article__title {
  color: $colorWhite;
  font-weight: 700;
  line-height: 1.6;
  margin-bottom: 0.6em;
  transition: color 0.2s linear;
  box-decoration-break: clone;
  &::after {
    position: absolute;
    content: '';
    left: 0;
    bottom: -0.2em;
    width: 100%;
    height: 1px;
    background-color: #fff;
    transition: transform 0.4s $easeOutExpo;
    transform: scaleX(0);
    transform-origin: top left;
  }

  @include styleS((
    font-size: 18,
  ));

  @include sizeL {
    font-size: 18px;
  }
}

.topicsDetail_p-article__body {
  color: $colorWhite;
  line-height: 2.5;

  @include styleS((
    font-size: 12,
    margin-top: 56
  ));

  @include sizeL {
    font-size: 12px;
    margin-top: 56px;
    line-height: 1.8;
  }

  p {
    margin-top: 2.5em;

    @include sizeL {
      margin-top: 1.8em;
    }
  }
}

.topicsDetail_p-article__category {
  @include absoluteTopRight;
  color: $colorWhite;
  background-color: $colorOrange;
  padding: 0.6em 1em;
  white-space: nowrap;
  transition: background-color 0.2s linear,
              color 0.2s linear;

  @include styleS((
    font-size: 12,
    right: 20
  ));

  @include sizeL {
    font-size: 12px;
    right: 0;
  }
}
@charset "utf-8";

$colorTwitter: #1da1f2;
$colorFacebook: #3b579d;
$colorLine: #00b900;
$colorYoutube: #ff0000;

$colorBlack: #000;
$colorWhite: #fff;
$colorOrange: #FF641E;

$maxWidth: 1920px;
$spDesignWidthBasis: 414;
$pcDesignWidthBasis: 1366;

// concact
.c-contactLink {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 90%;

  @include styleS((
    padding-top: 130,
    padding-bottom: 0
  ));

  @include sizeL {
    width: 520px;
    height: 620px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 0;
  }
}

.c-contactLink__inner {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 100%;
  }

  @include sizeL {
    width: 520px;
  }
}

.c-contactLink__label {
  @include absoluteCenterVHT;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: $colorOrange;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;

  .is-ie11 & {
    color: $colorOrange;
  }

  transition: -webkit-text-stroke-color 0.2s linear;

  .is-orangeBg & {
    -webkit-text-stroke-color: $colorWhite;
  }

  html:not(.is-noWebGL) & {
    visibility: hidden;
  }

  @include styleS((
    font-size: 53,
  ));

  @include sizeL {
    font-size: 53px;
  }
}

.c-contactLink .c-contactLink__circleArrow {
  @include absoluteCenterH;
  border-color: $colorOrange;
  top: 60%;

  &::after {
    border-color: $colorOrange;
  }

  @include styleS((
    width: 22,
    height: 22,
  ));

  @include sizeL {
    width: 22px;
  }
}
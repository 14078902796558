.p-menu {
  @include fixedTopLeft;
  height: 100%;
  width: 100%;
  background-color: $colorOrange;
  z-index: 200;
  overflow: hidden;
}

.menu-enter-active,
.menu-leave-active {
  transition: opacity 0.2s linear,
              visibility 0.2s linear;
}

.menu-enter,
.menu-leave-to {
  opacity: 0;
  visibility: hidden;
}
.menu-enter-to,
.menu-leave {
  opacity: 1;
  visibility: visible;
}

.p-menu__inner {
  position: relative;

  @include sizeL {
    height: 100%;
  }
}

.p-menu__scroller {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @include sizeL {
    min-height: 100%;
    justify-content: center;
    align-items: center;
  }
}

.p-menu__contents {
  width: 100%;
  height: auto;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @include styleS((
    padding-top: 36,
    padding-bottom: 18,
    padding-left: 16,
    padding-right: 16
  ));

  @include sizeL {
    max-width: 1024px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 80px;
    padding-top: 80px;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .is-ie11 & {
      @include absoluteCenterVHT;
    }
  }
}

.p-menu__logo {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  @include sizeL {
    @include absoluteCenterVT;
    left: 40px;
  }
}

.p-menu__logoMark {
  fill: $colorWhite;
  transition: fill 0.2s linear;
  display: grid;
  transform: translateY(4%);

  @include styleS((
    width: 50,
    height: 50,
    margin-right: 14
  ));

  @include sizeL {
    width: 38px;
    height: 36px;
    margin-right: 12px;
  }
}

.p-menu__logoTxt {
  fill: $colorWhite;
  transition: fill 0.2s linear;
  display: block;

  @include styleS((
    width: 110,
    height: 17,
  ));

  @include sizeL {
    width: 85px;
    height: 14px;
  }
}


.p-menuCloseButton {
  position: fixed;
  z-index: 11;

  @include styleS((
    width: 36,
    height: 36,
    right: 16 - 8,
    top: 52 - 13
  ));

  @include sizeL {
    width: 36px;
    height: 36px;
    right: #{36 - 8px};
    top: #{50 - 13px};
  }

  &::before, &::after {
    @include absoluteCenterVH;
    content: '';
    background-color: #fff;

    @include styleS((
      width: 20,
      height: 2,
      border-radius: 1
    ));

    @include sizeL {
      width: 20px;
      height: 2px;
      border-radius: 1px;
    }
  }

  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
}

.p-menuNav {
  @include styleS((
    margin-top: 56 - 6,
  ));

  @include sizeL {
    margin-top: 0;
  }
}


.p-menuNavItem,
.p-menuFunctionItem {
  position: relative;

  @include styleS((
    margin-top: 8,
  ));

  @include sizeL {
    margin-top: 10px;
  }

  &:first-child {
    margin-top: 0;
  }
}

.p-menuNavItem__inner,
.p-menuFunctionItem__inner {
  display: block;

  @include styleS((
    padding-top: 6,
    padding-bottom: 6,
  ));

  @include sizeL {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

.p-menuNavItem__label,
.p-menuFunctionItem__label {
  position: relative;
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  transition: transform 0.4s $easeOutExpo;

  @include styleS((
    font-size: 20,
  ));
  @include sizeL {
    font-size: 20px;
  }
}
.p-menuNavItem__labelJa,
.p-menuFunctionItem__labelJa {
  position: relative;
  display: block;
  margin-top: 0.8em;
  transition: transform 0.4s $easeOutExpo;

  @include styleS((
    font-size: 8,
  ));
  @include sizeL {
    font-size: 10px;
  }
}

@include hover {
  .p-menuNavItem:hover {
    .p-menuNavItem__label,
    .p-menuNavItem__labelJa {
      transition-duration: 0.2;
      transform: translateX(#{6 / $spDesignWidthBasis * 375px});

      @include sizeSS {
        transform: translateX(#{6 / $spDesignWidthBasis * 100vw});
      }

      @include sizeL {
        transform: translateX(6px);
      }
    }
  }

  .p-menuFunctionItem:hover {
    .p-menuFunctionItem__label,
    .p-menuFunctionItem__labelJa {
      transition-duration: 0.2;
      transform: translateX(#{6 / $spDesignWidthBasis * 375px});

      @include sizeSS {
        transform: translateX(#{6 / $spDesignWidthBasis * 100vw});
      }

      @include sizeL {
        transform: translateX(6px);
      }

      .c-circleArrow::after {
        transform: translateX(0%) rotate(45deg);
      }
    }
  }
}



.p-menu__functions {
  position: relative;

  &::before {
    @include absoluteTopLeft;
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #fff;
  }

  @include styleS((
    width: 274,
    padding-top: 32,
    margin-top: 32,
  ));

  @include sizeL {
    width: 274px;
    margin-top: 0;
    padding-top: 0;
    margin-left: 14%;

    &::before {
      top: 11em;
    }
  }

  @include sizeM {
    margin-left: 10%;
  }
}

.p-menu__copy {
  display: none;

  @include sizeL {
    margin-top: -0.5em;
    display: block;
    font-size: 24px;
    font-weight: 700;
    line-height: 2;
    white-space: nowrap;
    margin-bottom: 4em;
  }
}



.p-menuFunctionItem {
  .c-circleArrow {
    display: block;
    margin-left: 0.3em;

    @include styleS((
      width: 16,
      height: 16,
    ));

    @include sizeL {
      width: 16px;
      height: 16px;
    }

    &::after {
      transition: border-color 0.2s linear,
                  transform 0.4s $easeOutExpo;
    }
  }
}

.p-menuFunctionItem__label {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}


.p-menuSNS {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  @include styleS((
    margin-top: 32,
  ));

  @include sizeL {
    margin-top: 32px;
  }
}

.p-menuSNS__label {
  text-transform: uppercase;
  margin-right: 1em;

  @include sizeL {
    font-size: 10px;
  }
}

.p-menuSNSLink {
  position: relative;

  @include styleS((
    width: 32,
    height: 32,
  ));

  @include sizeL {
    width: 32px;
    height: 32px;
  }
}

.p-menuSNSLink__inner {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    fill: #fff;
    display: block;
    height: 100%;
    transition: transform 0.4s $easeOutExpo;

    @include styleS((
      width: 17,
    ));
    @include sizeL {
      width: 17px;
    }
  }
}

@include hover {
  .p-menuSNSLink:hover {
    .p-menuSNSLink__inner svg {
      transform: scale(1.2);
      transition-duration: 0.2s;
    }
  }
}


.p-menu__bottom {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @include styleS((
    margin-top: 76,
    padding-bottom: 18,
    padding-left: 16,
    padding-right: 16
  ));

  @include sizeL {
    @include absoluteCenterHT;
    bottom: 0;
    max-width: 1024px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 30px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
  }
}

.p-menu__copyright {
  position: relative;
  margin-left: auto;

  @include styleS((
    font-size: 10,
  ));

  @include sizeL {
    font-size: 10px;
  }
}


.p-menuPrivacyLink {
  position: relative;


  @include styleS((
    font-size: 10,
  ));

  @include sizeL {
    font-size: 10px;
  }
}

.p-menuPrivacyLink__inner {
  display: block;
  text-transform: uppercase;

  @include styleS((
    padding-top: 6,
    padding-bottom: 6,
  ));
  @include sizeL {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

.p-menuPrivacyLink__label {
  position: relative;
  display: block;

  &::after {
    position: absolute;
    content: '';
    left: 0;
    bottom: -0.2em;
    width: 100%;
    height: 1px;
    background-color: #fff;
    transition: transform 0.4s $easeOutExpo;
    transform: scaleX(0);
    transform-origin: top left;
  }
}

@include hover {
  .p-menuPrivacyLink:hover {
    .p-menuPrivacyLink__label::after {
      transform: scaleX(1);
    }
  }
}


@import "header";
@import "footer";
@import "menu";
@import "modal";
@import "menuButton";
@import "rootLoading";

.p-scroller {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;

  .is-ios &,
  .is-ie11 & {
    position: relative;
  }

  @include sizeL {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 100vh;
  }
}

.p-scrollerSpacer {
  position: relative;
  width: 100%;

  .is-ios &,
  .is-ie11 & {
    display: none;
  }
}

@charset "utf-8";

// reset box-sizing to border-box
html {
  position: relative;
  box-sizing: border-box;
  margin: auto;
  padding: 0;
  display: block;
}

*, *:before, *:after {
  box-sizing: inherit;
  letter-spacing: 0.1em !important;
}

main, section {
  display: block;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
}
input[type="checkbox"] {
  margin: 0;
  padding: 0;
  border-radius: 0;
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

svg {
  transform-box: fill-box;
}

body {
  position: relative;
  display: block;
  width: 100%;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: "YakuHanJP_Noto", "Noto Sans JP", sans-serif;
  font-weight: 500;
  letter-spacing: 0.1em !important;
  // font-feature-settings: "palt";
  // transition: background-color 0.1s linear;
  background-color: $colorBlack;
  color: $colorWhite;

  transition: background-color 0.6s linear;

  &.is-orangeBg {
    background-color: $colorOrange;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

*::selection {
}

.p-modal {
  @include fixedTopLeft;
  height: 100%;
  width: 100%;
  background-color: $colorOrange;
  z-index: 300;
  overflow: hidden;
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.2s linear,
              visibility 0.2s linear;
}

.modal-enter,
.modal-leave-to {
  opacity: 0;
  visibility: hidden;
}
.modal-enter-to,
.modal-leave {
  opacity: 1;
  visibility: visible;
}

.p-modal__inner {
  position: relative;

  @include sizeL {
    height: 100%;
  }
}

.p-modal__scroller {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @include sizeL {
    min-height: 100%;
    justify-content: center;
    align-items: center;
  }
}

.p-modal__contents {
  width: 100%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @include styleS((
    padding-top: 200,
    padding-bottom: 80,
    padding-left: 16,
    padding-right: 136
  ));

  @include sizeL {
    max-width: 936 + 200px;
    padding-left: 100px;
    padding-right: 360px;
    padding-bottom: 160px;
    padding-top: 160px;
  }

  @include sizeM {
    max-width: 936 + 200px;
    padding-left: 60px;
    padding-right: 320px;
    padding-bottom: 100px;
    padding-top: 100px;
  }
}

.p-modal__logo {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  @include sizeL {
    @include absoluteCenterVT;
    left: 40px;
  }
}

.p-modal__logoMark {
  fill: $colorWhite;
  transition: fill 0.2s linear;
  display: grid;
  transform: translateY(4%);

  @include styleS((
    width: 50,
    height: 50,
    margin-right: 14
  ));

  @include sizeL {
    width: 38px;
    height: 36px;
    margin-right: 12px;
  }
}

.p-modal__logoTxt {
  fill: $colorWhite;
  transition: fill 0.2s linear;
  display: block;

  @include styleS((
    width: 110,
    height: 17,
  ));

  @include sizeL {
    width: 85px;
    height: 14px;
  }
}

.p-modal__head {
  @include absoluteTopRight;
  z-index: 10;

  @include sizeL {
    @include absoluteCenterVT;
    right: 100px;
  }

  @include sizeM {
    right: 60px;
  }

  @media screen and (min-width:1136px) {
    right: auto;
    left: calc(50% + 468px);
    transform: translate(-100%, -50%);
  }
}


.p-modalCloseButton {
  position: absolute;
  z-index: 11;

  @include styleS((
    width: 86,
    height: 36,
    right: 16 - 8,
    top: 52 - 13
  ));

  @include sizeL {
    width: 86px;
    height: 36px;
    right: -8px;
    top: -8px;
  }

  &::before, &::after {
    @include absoluteCenterV;
    content: '';
    background-color: #fff;

    @include styleS((
      width: 20,
      height: 2,
      border-radius: 1,
      right: 8
    ));

    @include sizeL {
      width: 20px;
      height: 2px;
      border-radius: 1px;
      right: 8px;
    }
  }

  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
}

.p-modalCloseButton__label {
  @include absoluteCenterVT;
  color: #fff;
  text-transform: uppercase;

  @include styleS((
    font-size: 11,
    right: 36
  ));

  @include sizeL {
    font-size: 11px;
    right: 36px;
  }
}

.p-modal__headContents {
  position: relative;

  @include styleS((
    padding-top: 200,
    padding-right: 16,
  ));

  @include sizeL {
    padding-top: 60px;
    padding-right: 0;
  }
}




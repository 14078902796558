// ===============================
// Object
// ===============================

// component



// Project

.contact_p-main {
  position: relative;
  display: block;

  .c-smallTitle {
    justify-content: center;
  }
}

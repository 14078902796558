// ===============================
// Object
// ===============================

// component

// topicsItem
.index_c-topicsItem {
  position: relative;

  @include styleS((
    margin-top: 30
  ));


  &:first-child {
    margin-top: 0
  }

  @include sizeL {
    margin-top: 0;

    &:first-child {
      grid-row: 1 / 4;
      grid-column: 1 / 2;
    }

    &:nth-child(2) {
      grid-row: 1 / 2;
      grid-column: 2 / 3;
    }
    &:nth-child(3) {
      grid-row: 2 / 3;
      grid-column: 2 / 3;
    }
    &:nth-child(4) {
      grid-row: 3 / 4;
      grid-column: 2 / 3;
    }
  }
}

.index_c-topicsItem__inner {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  @include styleS((
    padding-left: 20,
  ));

  .index_c-topicsItem:first-child & {
    display: block;
    padding-left: 0;
  }

  @include sizeL {
    padding-left: 0;

    .index_c-topicsItem:first-child & {
      display: flex;
      flex-direction: column;
    }
  }

  @include sizeM {
    .index_c-topicsItem:first-child & {
      flex-direction: row;
    }
  }
}

.index_c-topicsItem__thumb {
  position: relative;
  background-color: rgba($colorWhite, 0.1);
  overflow: hidden;

  @include styleS((
    width: 126,
    min-width: 126,
    margin-right: 20,
  ));

  .index_c-topicsItem:first-child & {
    width: 100%;
    margin-right: 0;

    @include styleS((
      margin-bottom: 24,
    ));
  }

  @include sizeL {
    width: 165px;
    min-width: 165px;
    margin-right: 24px;

    .index_c-topicsItem:first-child & {
      width: 300px;
      margin-right: 0;
      margin-bottom: 16px;
    }
  }

  @include sizeM {
    .index_c-topicsItem:first-child & {
      width: 165px;
      min-width: 165px;
      margin-right: 24px;
      margin-bottom: 0;
    }
  }

  @include sizeL2 {
    width: 200px;
    min-width: 200px;

    .index_c-topicsItem:first-child & {
      width: 400px;
    }
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 7 / 10 * 100%;
  }

  img {
    @include inner100Absolute;
    transition: transform 2s $easeOutExpo,
                opacity 0.4s linear !important;
    object-fit: cover;
  }
}

.index_c-topicsItem__contents {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .index_c-topicsItem:first-child & {
    @include styleS((
      padding-left: 20,
      padding-right: 20,
    ));
  }

  @include sizeL {
    padding: 0 !important;
  }
}

.index_c-topicsItem__date {
  color: $colorWhite;
  margin-bottom: 1em;

  @include styleS((
    font-size: 10
  ));

  @include sizeL {
    font-size: 10px;
  }
}

.index_c-topicsItem__title {
  color: $colorWhite;
  font-weight: 700;
  line-height: 1.6;
  margin-bottom: 0.6em;
  transition: color 0.2s linear;
  box-decoration-break: clone;
  &::after {
    position: absolute;
    content: '';
    left: 0;
    bottom: -0.2em;
    width: 100%;
    height: 1px;
    background-color: #fff;
    transition: transform 0.4s $easeOutExpo;
    transform: scaleX(0);
    transform-origin: top left;
  }

  @include styleS((
    font-size: 14,
  ));

  @include sizeL {
    font-size: 14px;
  }
}

.index_c-topicsItem__description {
  // color: #b0b0b0; // gray 70%
  color: rgba($colorWhite, 0.7); // gray 70%
  line-height: 1.8;
  display: none;
  margin-bottom: 1em;

  .index_c-topicsItem:first-child & {
    display: block;
  }

  @include styleS((
    font-size: 10,
  ));

  @include sizeL {
    display: block;
    font-size: 10px;
  }
}

.index_c-topicsItem__category {
  color: $colorWhite;
  background-color: $colorOrange;
  padding: 0.6em 1em;
  transition: background-color 0.2s linear,
              color 0.2s linear;

  .is-orangeBg & {
    color: $colorOrange;
    background-color: $colorWhite;
  }

  @include styleS((
    font-size: 10,
  ));

  @include sizeL {
    font-size: 10px;
  }
}

@include hover {
  .index_c-topicsItem:hover {
    .index_c-topicsItem__thumb img {
      transform: scale(1.1);
    }
    .index_c-topicsItem__title {
      color: $colorOrange;
      transition-duration: 0.1s;

      .is-orangeBg & {
        color: $colorWhite;
      }
    }
  }
}


// othersItem
.index_c-othersItem {
  position: relative;
  width: 100%;

  @include styleS((
    margin-top: 72,
  ));

  @include sizeL {
    width: 664px;
    min-width: 664px;
    margin-top: 0;
    margin-left: 96px;
  }

  @include sizeM {
    width: 500px;
    min-width: 500px;
    margin-left: 48px;
  }

  &:first-child {
    margin-left: 0;
    margin-top: 0;
  }
}

.index_c-othersItem__inner {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.index_c-othersItem__contents {
  position: relative;
  width: 100%;

  @include styleS((
    padding-left: 20,
    padding-right: 20,
  ));

  @include sizeL {
    padding: 0;
  }
}

.index_c-othersItem__thumb {
  width: 100%;
  background-color: rgba($colorWhite, 0.1);
  position: relative;
  overflow: hidden;

  @include styleS((
    margin-bottom: 36,
  ));

  @include sizeL {
    margin-bottom: 42px;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 393 / 664 * 100%;
  }

  img {
    @include inner100Absolute;
    transition: transform 2s $easeOutExpo,
                opacity 0.4s linear !important;
    display: block;
    object-fit: cover;
  };
}

.index_c-othersItem__title {
  text-transform: uppercase;
  color: $colorWhite;
  transition: color 0.2s linear;


  @include styleS((
    font-size: 14,
  ));

  @include sizeL {
    font-size: 14px;
  };
}

.index_c-othersItem__viewDetail {
  position: absolute;

  @include styleS((
    top: -4,
    right: 20,
  ));

  .c-circleArrowLink__label {
    color: $colorWhite;
  }

  .c-circleArrowLink__circleArrow {
    border-color: $colorWhite;

    &::after {
      border-color: $colorWhite;
    }
  }

  @include sizeL {
    top: 0;
    right: 0;
  }
}

@include hover {
  .index_c-othersItem:hover {
    .index_c-othersItem__thumb img {
      transform: scale(1.06);
    }
    .index_c-othersItem__viewDetail {
      .c-circleArrowLink__label::after {
        transform: scaleX(1);
      }
      .c-circleArrowLink__circleArrow::after {
        transform: translateX(0%) rotate(45deg);
      }
    }

  }
}




// kvItem
.index_c-kvItem {
  @include inner100Absolute;
  backface-visibility: hidden;
}

.index_c-kvItem__inner {
  @include inner100Block;
  backface-visibility: hidden;
}

.index_c-kvItem__link {
  @include absoluteCenterHT;

  @include styleS((
    bottom: 36,
  ));

  @include sizeL {
    bottom: 36px;
  }
}

.index_c-kvItem__mainCopy {
  @include absoluteCenterVHT;
  @include fontCutive;
  // color: $colorOrange;
  color: #fff;
  text-align: center;
  line-height: 1.78;
  backface-visibility: hidden;

  @include styleS((
    font-size: 18,
  ));

  @include sizeL {
    font-size: 18px;
    flex-direction: row;
  }

  p.is-hidden {
    @include absoluteCenterVHT;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    white-space: nowrap;
    letter-spacing: 0.2em !important;
  }
}

.index_c-kvItem__mainCopyLineWrapper {
  backface-visibility: hidden;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.index_c-kvItem__mainCopyLine {
  letter-spacing: 0.2em !important;
  white-space: nowrap;
  backface-visibility: hidden;

  @include sizeL {
    margin-left: 0.4em;

    &:first-child {
      margin-left: 0;
    }
  }
}

.index_c-kvItem__mainCopyChar {
  letter-spacing: 0.2em !important;
  position: relative;
  display: inline-block;
  backface-visibility: hidden;
}

.index_c-kvItem__description {
  @include absoluteCenterHT;
  color: $colorWhite;
  line-height: 2.2;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include styleS((
    font-size: 10,
    bottom: 84,
    width: 340
  ));

  @include sizeL {
    font-size: 12px;
    bottom: 84px;
    width: 780px;
    flex-wrap: wrap;
    flex-direction: row;
  }
}

.index_c-kvItem__descriptionLine {
  font-weight: 400 !important;
  letter-spacing: 0.24em !important;
  display: inline;
  text-align: center;
}

.index_c-kvItem__visual {
  @include absoluteCenterVHT;
  backface-visibility: hidden;
  width: 100%;

  &::before {
    display: block;
    widows: 100%;
    padding-top: 100%;
    content: '';
  }

  img {
    @include inner100Absolute;
    display: block;
    opacity: 0.5;
  }

  @include sizeL {
    widows: 80%;
    max-width: 800px;
  }
}

.indexKVItem-enter-active {
  transition: opacity 0.4s linear,
              visibility 0.4s linear;

  .index_c-kvItem__mainCopyChar {
    transition: transform 0.8s $easeOutExpo,
                opacity 0.4s linear;
  }

  .index_c-kvItem__description {
    transition: transform 0.6s $easeOutExpo 0.4s,
                opacity 0.3s linear 0.4s;
  }
  .index_c-kvItem__link {
    transition: transform 0.6s $easeOutExpo 0.6s,
                opacity 0.3s linear 0.6s;
  }
}

.indexKVItem-leave-active {
  transition: opacity 0.4s linear,
              visibility 0.4s linear;
}

.indexKVItem-enter {
  opacity: 0;
  visibility: hidden;

  .index_c-kvItem__mainCopyChar {
    transform: translateY(60%);
    opacity: 0;
  }

  .index_c-kvItem__description,
  .index_c-kvItem__link {
    transform: translate(-50%, 8px);
    opacity: 0;

    @include sizeSS {
      transform: translate(-50%, 8 / $spDesignWidthBasis * 100vw);
    }

    @include sizeL {
      transform: translate(-50%, 8px);
    }
  }
}

.indexKVItem-leave-to {
  opacity: 0;
  visibility: hidden;
}

.indexKVItem-enter-to {
  opacity: 1;
  visibility: visible;

  .index_c-kvItem__mainCopyChar {
    transform: translateY(0);
    opacity: 1;
  }

  .index_c-kvItem__description,
  .index_c-kvItem__link {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}

.indexKVItem-leave {
  opacity: 1;
  visibility: visible;
}


// kvIndicatorItem
.index_c-kvIndicatorItem {
  position: relative;
  backface-visibility: hidden;

  @include styleS((
    margin-top: 10,
    width: 30,
    height: 30
  ));

  &:first-child {
    margin-top: 0;
  }

  @include sizeL {
    margin-top: 14px;
    width: 40px;
    height: 40px;
  }

  &::after {
    @include absoluteCenterVHT;
    content: '';
    border-radius: 50%;
    background-color: $colorWhite;
    transition: opacity 0.2s linear;
    opacity: 0.2;

    @include styleS((
      width: 3,
      height: 3,
    ));

    @include sizeL {
      width: 3px;
      height: 3px;
    }
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: transparent;
    backface-visibility: hidden;
  }

  &.is-current {
    &::after {
      opacity: 1;
    }
  }
}

.index_c-kvIndicatorItem__inner {
  position: absolute;
  left: -10%;
  top: -10%;
  width: 120%;
  height: 120%;
  backface-visibility: hidden;
  // transform: rotate(-90deg) translateZ(0);
}

.index_c-kvIndicatorItem__circleBase {
  @include inner100Absolute;
  opacity: 0.2;
  backface-visibility: hidden;

  svg {
    stroke: $colorWhite;
    stroke-width: 1px;
  }
}

.index_c-kvIndicatorItem__circle {
  @include inner100Absolute;
  backface-visibility: hidden;
  position: absolute;
  opacity: 0;
  z-index: 2;
  transition: opacity 0.2s linear;

  svg {
    stroke: $colorWhite;
    stroke-width: 1px;
    stroke-dasharray: 108;
    stroke-dashoffset: 108;
    will-change: stroke-dashoffset;
    transition: stroke-dashoffset 7s linear;
  }


  .is-playing .index_c-kvIndicatorItem.is-current & {
    opacity: 1;

    svg {
      stroke-dashoffset: 0;
    }
  }
}



// Project

.index_p-main {
  position: relative;
  display: block;
}


// kv
.index_p-kv {
  position: relative;
  width: 100%;
  backface-visibility: hidden;
  min-height: 10px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 0.4s linear,
              visibility 0.4s linear;

  &.is-playing {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
}

.index_p-kv__dummyVisual {
  @include absoluteCenterVHT;
  backface-visibility: hidden;
  width: 100%;
  z-index: -2;

  &::before {
    display: block;
    widows: 100%;
    padding-top: 100%;
    content: '';
  }

  @include sizeL {
    widows: 80%;
    max-width: 800px;
  }
}

.index_p-kvItems {
  position: relative;
  @include inner100Block;
}

.index_p-kvIndicatorItems {
  @include absoluteCenterVT;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include styleS((
    width: 30,
    right: 16
  ));

  @include sizeL {
    width: 40px;
    right: 90px;
  }
}

.index_p-kvScrollButton {
  transform: rotate(90deg) translateY(100%);
  position: absolute;
  bottom: 0;
  transform-origin: bottom right;
  right: 0;
  z-index: 2;

  @include styleS((
    width: 174,
  ));

  @include sizeL {
    width: 174px;
    transform: translateX(-6%) rotate(90deg) translateY(100%);
  }
}

.index_p-kvScrollButton__inner {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1em 2em 1em 1em;
}

@keyframes indexScrollButtonLine {
  0% {
    transform-origin: top left;
    transform: scaleX(0) translateZ(0);
  }
  50% {
    transform-origin: top left;
    transform: scaleX(1) translateZ(0);
  }
  51% {
    transform-origin: top right;
    transform: scaleX(1) translateZ(0);
  }
  53%{
    transform: scaleX(1) translateZ(0);
  }
  100% {
    transform-origin: top right;
    transform: scaleX(0) translateZ(0);
  }
}

.index_p-kvScrollButton__line {
  height: 1px;
  width: 100%;
  background-color: rgba($colorWhite, 0.1);
  position: relative;
  display: block;
  backface-visibility: hidden;

  &::after {
    @include inner100Absolute;
    backface-visibility: hidden;
    top: 0;
    left: 0;
    content: '';
    background-color: $colorWhite;
    will-change: transform, transform-origin;
    animation: indexScrollButtonLine 1.4s $easeInOutCubic 0s infinite;
  }
}

.index_p-kvScrollButton__label {
  color: $colorWhite;
  padding-right: 1.6em;
  white-space: nowrap;

  @include styleS((
    font-size: 10,
  ));

  @include sizeL {
    font-size: 10px;
  }
}

// service and about
.index_p-serviceAndAbout {
  position: relative;

  @include styleS((
    padding-top: 60,
    padding-bottom: 178,
  ));
  @include sizeL {
    padding-top: 192px;
    padding-bottom: 160px;
  }
  @include sizeM {
    padding-top: 160px;
    padding-bottom: 100px;
  }
}

.index_p-serviceAndAbout__inner {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 1300px;

  @include styleS((
    padding-left: 36,
    padding-right: 36,
  ));
  @include sizeL {
    padding-left: 100px;
    padding-right: 100px;
  }
  @include sizeM {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.index_p-serviceAndAboutItem {
  position: relative;

  @include sizeL {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }
}

.index_p-serviceAndAboutItem__inner {
  position: relative;

  @include sizeL {
    width: 50%;
    padding-left: 6%;
  }
}

.index_p-serviceAndAboutItem__visual {
  position: relative;
  width: 100%;
  padding-top: 100%;

  @include sizeL {
    width: 50%;
    padding-top: 50%;
  }

  img {
    @include inner100Absolute;

    html:not(.is-noWebGL) & {
      display: none;
    }
  };
}

.index_p-service {
  @include sizeL {
    margin-bottom: 100px;
  }
}

.index_p-about {
  flex-direction: row-reverse;

  @include styleS((
    margin-top: 60,
  ));

  @include sizeL {
    margin-top: 0;

    .index_p-serviceAndAboutItem__inner {
      padding-left: 120px;
    }
  }
}

.index_p-serviceAndAboutItem__title {
  text-transform: uppercase;
  color: $colorOrange;
  transition: color 0.2s linear;

  .is-orangeBg & {
    color: $colorWhite;
  }

  @include styleS((
    font-size: 13,
    margin-bottom: 20
  ));

  @include sizeL {
    font-size: 11px;
    margin-bottom: 20px;
  }
}

.index_p-serviceAndAboutItem__copy {
  font-weight: 700;
  line-height: 2;
  white-space: nowrap;

  @include styleS((
    font-size: 20,
  ));
  @include sizeL {
    font-size: 20px;
  }
  @include sizeM {
    font-size: 18px;
  }
  @include sizeL2 {
    font-size: 26px;
  }
}

.index_p-serviceAndAboutItem__description {
  line-height: 2.5;
  white-space: nowrap;

  @include styleS((
    font-size: 12,
    margin-top: 20,
  ));
  @include sizeL {
    font-size: 12px;
    margin-top: 20px;
  }
  @include sizeM {
    font-size: 11px;
    margin-top: 18px;
  }
  @include sizeL2 {
    font-size: 14px;
    margin-top: 30px;
  }
}

.index_p-serviceAndAboutItem__link {
  @include styleS((
    margin-top: 30,
  ));
  @include sizeL {
    margin-top: 30px;
  }
  @include sizeL2 {
    margin-top: 45px;
  }
}





// others
.index_p-others {
  position: relative;
  overflow: hidden;

  @include styleS((
    padding-top: 96,
    padding-bottom: 88,
    padding-left: 16,
    padding-right: 16,
  ));

  @include sizeL {
    padding-top: 160px;
    padding-bottom: 160px;
    padding-left: 0;
    padding-right: 0;
    // margin-top: 210px;
  }

  @include sizeM {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.index_p-others__inner {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  @include sizeL {
    max-width: #{664px * 5 + 96px * 2 + 35px * 2};
    transition: opacity 0.4s linear 0.4s;
    opacity: 0;

    .index_p-others.is-visible & {
      opacity: 1;
    }
  }

  @include sizeM {
    max-width: #{500px * 5 + 48px * 2 + 35px * 2};
  }
}

.index_p-others__scroller {
  @include sizeL {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 35 + 664px;
    padding-right: 35 + 664px;
    width: #{664px * 5 + 96px * 2 + 35px * 2};
  }

  @include sizeM {
    padding-left: 35 + 500px;
    padding-right: 35 + 500px;
    width: #{500px * 5 + 48px * 2 + 35px * 2};
  }
}



// topics
.index_p-topics {
  position: relative;
}

.index_p-topics__title {
  text-transform: uppercase;
  color: $colorOrange;
  transition: color 0.2s linear;

  .is-orangeBg & {
    color: $colorWhite;
  }

  @include styleS((
    font-size: 13,
    margin-bottom: 44,
    padding-left: 20
  ));

  @include sizeL {
    font-size: 11px;
    margin-bottom: 74px;
    padding-left: 0;
  }
}

.index_p-topicsLink {
  position: absolute;

  @include styleS((
    right: 36,
    top: 136,
  ));

  @include sizeL {
    right: 40px;
    top: 190px;
  }
}

.index_p-topics__inner {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 1300px;

  @include styleS((
    padding-top: 140,
    padding-bottom: 30,
    padding-left: 16,
    padding-right: 16,
  ));

  @include sizeL {
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 190px;
    padding-bottom: 190px;
  }
}

.index_p-topicsList {
  position: relative;

  @include sizeL {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 300px 1fr;
    grid-gap: 30px 65px;
  }

  @include sizeM {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  @include sizeL2 {
    grid-template-columns: 400px 1fr;
  }
}




// indexLoading
.p-indexLoading {
  @include fixedTopLeft;
  width: 100%;
  height: 100%;
  background-color: $colorBlack;
  z-index: 1002;
}

.indexLoading-enter-active,
.indexLoading-leave-active {
  transition: opacity 0.4s linear,
              visibility 0.4s linear;
}

.indexLoading-enter,
.indexLoading-leave-to {
  opacity: 0;
  visibility: hidden;
}
.indexLoading-enter-to,
.indexLoading-leave {
  opacity: 1;
  visibility: visible;
}

.p-indexLoading__copy {
  @include absoluteCenterH;
  transition: fill 0.2s linear;
  fill: $colorWhite;
  bottom: 50%;

  @include styleS((
    width: 246,
    height: 116,
    margin-bottom: 68
  ));

  .is-loaded & {
    fill: $colorOrange;
  }
}

.p-indexLoading__copyL {
  @include absoluteCenterH;
  transition: fill 0.2s linear;
  fill: $colorWhite;
  bottom: 50%;

  @include sizeL {
    width: 706px;
    height: 26px;
    margin-bottom: 23px;
  }

  .is-loaded & {
    fill: $colorOrange;
  }
}

.p-indexLoading__logo {
  @include absoluteCenterH;
  transition: fill 0.2s linear;
  fill: $colorWhite;
  top: 50%;

  @include styleS((
    width: 85,
    height: 13,
    margin-top: 68
  ));

  @include sizeL {
    width: 85px;
    height: 13px;
    margin-top: 23px;
  }

  .is-loaded & {
    fill: $colorOrange;
  }
}

.p-indexLoadingBar {
  @include absoluteCenterV;
  width: 100%;
  height: 1px;
  background-color: rgba($colorWhite, 0.1);
}

.p-indexLoadingBar__inner {
  @include inner100Absolute;
  background-color: $colorOrange;
  transform-origin: top left;
  transition: transform 0.4s $easeOutExpo;
}

.p-indexLoadingIndicator {
  @include absoluteCenterH;
  font-family: sans-serif !important;
  color: $colorOrange;
  letter-spacing: 0.2em;
  padding: 1em 1.4em;
  text-align: center;
  transition: transform 0.6s $easeOutExpo,
              opacity 0.3s linear,
              visibility 0.3s linear;

  @include styleS((
    font-size: 13,
    bottom: 50,
  ));

  @include sizeL {
    font-size: 10px;
    bottom: 30px;
  }

  .is-loaded & {
    transform: translateY(20%);
    opacity: 0;
    visibility: hidden;
  }
}

.p-indexLoadingEnterButton {
  @include absoluteCenterH;
  font-family: sans-serif !important;
  color: $colorOrange;
  text-transform: uppercase;
  transform: translateY(20%);
  opacity: 0;
  visibility: hidden;
  transition: transform 0.6s $easeOutExpo 0.4s,
              opacity 0.3s linear 0.4s,
              visibility 0.3s linear 0.4s;

  @include styleS((
    font-size: 13,
    bottom: 50,
  ));

  @include sizeL {
    font-size: 10px;
    bottom: 30px;
  }

  .is-loaded & {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
}

.p-indexLoadingEnterButton__inner {
  padding: 1em 1.4em;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

@keyframes indexEnterCharAnim {
  0% { transform: translateY(0) }
  20% { transform: translateY(-0.6em) }
  40% { transform: translateY(0) }
  100% { transform: translateY(0) }
}

.p-indexLoadingEnterButton__char {
  display: block;
  letter-spacing: 0.4em !important;

  $wait: 0.4s;
  $interval: 0.1s;

  @for $var from 1 to 6 {
    .is-loaded &:nth-child(#{$var}) {
      animation: indexEnterCharAnim 1.6s $easeInOutSine #{$wait + $interval * ($var - 1)} infinite;
    }
  }
}


// mainGL
.p-mainGL {
  @include fixedTopLeft;
  width: 100%;
  height: 100vh;
  transition: transform 0.8s $easeOutExpo;
}